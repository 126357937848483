import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router';
import { SubNavigationBar } from '@common/components/navigation-bar';
import Container from '@common/components/container';
import PulseSurveysOverview from '@modules/survey/components/pulse-surveys/pulse-surveys-overview';
import { pageWrapper, EEventNames } from '../../../../../client/analytics';
import * as organisationSelector from '../../../organisation/selectors/organisation';
import * as userSelector from '../../../core/selectors/logged-user';
import * as surveySelector from '../../selectors/surveys';
import CustomSurveys from './custom-surveys';

const SurveysContainer = (props) => {
  const { counts, t, fetchSurveys } = props;

  const org = useSelector(organisationSelector.selected);

  useEffect(() => {
    // this fetches the custom surveys counts even if the custom surveys page
    // is not currently viewed, so we can add the counts to the sidebar
    fetchSurveys(true, { status: 'draft' }, 1);
  }, [fetchSurveys]);

  return (
    <>
      <SubNavigationBar title={t('core:tab_surveys')}>
        <SubNavigationBar.Subtitle>
          { t('survey:pulse_surveys_sidebar_subtitle') }
        </SubNavigationBar.Subtitle>
        <SubNavigationBar.Item
          exact
          path="/admin/surveys/pulse-overview"
          title={t('survey:pulse_sidebar_overview_section')}
        />

        <SubNavigationBar.Subtitle>
          { t('survey:custom_surveys_sidebar_subtitle') }
        </SubNavigationBar.Subtitle>
        <SubNavigationBar.Item
          exact
          icon="chat"
          path="/admin/surveys"
          title={t('common:filter_all')}
          count={counts.total_count && counts.total_count - counts.archived_count}
        />
        <SubNavigationBar.Item
          icon="draft"
          path="/admin/surveys/filter/draft"
          title={t('common:status_draft')}
          count={counts.draft_count}
        />
        <SubNavigationBar.Item
          icon="play_arrow"
          path="/admin/surveys/filter/live"
          title={t('common:status_live')}
          count={counts.live_count}
        />
        <SubNavigationBar.Item
          icon="check"
          path="/admin/surveys/filter/completed"
          title={t('common:status_completed')}
          count={counts.completed_count}
        />
        <SubNavigationBar.Item
          icon="inventory"
          path="/admin/surveys/filter/archived"
          title={t('common:status_archived')}
          count={counts.archived_count}
        />
        <SubNavigationBar.Item
          icon="access_time"
          path="/admin/surveys/filter/scheduled"
          title={t('common:status_scheduled')}
          count={counts.scheduled_count}
        />
      </SubNavigationBar>
      <Container name="Surveys">
        <Switch>
          <Route path="/admin/surveys/pulse-overview">
            <PulseSurveysOverview organisation={org} />
          </Route>
          <Route>
            <CustomSurveys {...props} />
          </Route>
        </Switch>
      </Container>
    </>
  );
};

// $FlowFixMe
const mapStateToProps = (state) => ({
  loggedUser: userSelector.selected(state),
  organisation: organisationSelector.selected(state),
  surveys: surveySelector.all(state),
  templates: surveySelector.templates(state),
  counts: state.survey.counts,
});

const mapDispatchToProps = {
  fetchSurveys: require('../../actions').fetchSurveys,
  fetchTemplates: require('../../actions').fetchTemplates,
  createSurvey: require('../../actions').createSurvey,
  deleteSurvey: require('../../actions').deleteSurvey,
  archiveSurvey: require('../../actions').archiveSurvey,
  duplicateSurvey: require('../../actions').duplicateSurvey,
  completeSurvey: require('../../actions').completeSurvey,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(
    pageWrapper(EEventNames.VISITED_SURVEYS_PAGE)(SurveysContainer)
  )
);
