const getValue = (style, value) => parseInt(style[value], 10) || 0;

const getFirstElementOfClass = (className) => {
  const elements = document.getElementsByClassName(className);
  return elements[0];
};

export const getHeightOfFirstElement = (className) => {
  const element = getFirstElementOfClass(className);
  if (!element) {
    return null;
  }
  return element.offsetHeight;
};

export const shouldCallCallback = (scrollPosition, height, parentHeight) => (
  height - parentHeight - scrollPosition < parentHeight);

export const isNearBottom = () => {
  let isFetching = false;
  let store = {};
  // Prevent callback from happening when initialising
  store[0] = true;

  return {
    reset: () => {
      store = {};
    },
    addScrollListener: (element, callback) => {
      if (!element) return false;

      // $FlowFixMe
      element.addEventListener('scroll', async ({ target }) => {
        const style = getComputedStyle ? getComputedStyle(target) : {};
        const contentHeight = target.children[0].clientHeight;

        if (!isFetching && !store[contentHeight] && shouldCallCallback(
          target.scrollTop,
          contentHeight,
          target.clientHeight - getValue(style, 'paddingTop') - getValue(style, 'paddingBottom'),
        )) {
          store[contentHeight] = true;

          isFetching = true;
          await callback();
          isFetching = false;
        }
      });

      return true;
    },
  };
};

export const getScrollableParent = (el) => (
  el && el.scrollHeight > el.clientHeight
    ? el
    // $FlowFixMe el.parentNode returns Node instead of HTMLElement
    : el && el.parentNode && getScrollableParent(el.parentNode));
