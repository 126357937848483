import moment from 'moment';

export const formatParticipationRate = (rate: number) => {
  if (rate > 100) {
    return (100).toFixed(1);
  }
  return rate.toFixed(1);
};

export const getStartDate = (predicates: any[]) => {
  const [date]: any[] = predicates;
  return date.value.value[0];
};

export const getEndDate = (predicates: any[]) => {
  const [date]: any[] = predicates;
  return date.value.value[1];
};

export const getDates = (predicates: any[]) => {
  return {
    startDate: getStartDate(predicates),
    endDate: getEndDate(predicates)
  };
};

export const getCategorySeriesLimitDates = (series: any[]) => {
  if (!Array.isArray(series) || series.length <= 0) {
    return {
      startDate: null,
      endDate: null
    };
  }
  return {
    startDate: series[0].date,
    endDate: series[series.length - 1].date
  };
};

export const getCategoryLimitDates = (category: Record<string, any>) => {
  return getCategorySeriesLimitDates(category?.time_series?.current);
};

export const getDateParams = (predicates: any[]) => {
  const [date]: any[] = predicates;
  const from = `from_date=${moment(date.value.value[0]).format('YYYY-MM-DD')}`;
  const to = `to_date=${moment(date.value.value[1]).format('YYYY-MM-DD')}`;

  return [from, to];
};

export const getDateQuery = (predicates: any[]) => {
  const [from, to] = getDateParams(predicates);
  return `${from}&${to}`;
};

export const formatDate = (date: string | Date) => {
  return moment(date).format('DD/MM/YYYY');
};

export const getAnalyticsUrl = (orgId: string, surveyId: string) => {
  return `/v1/organisations/${orgId}/pulse_surveys/${surveyId}/analytics`;
};

export const addParams = (path: string, predicates: any[]) => {
  let result = `${path}?${getDateQuery(predicates)}`;

  const networkIds = predicates[1].value.value.map((id: string) => {
    return `network_ids%5B%5D=${id}`;
  }).join('&');
  if (networkIds) {
    result += `&${networkIds}`;
  }

  const functionIds = predicates[2].value.value.map((id: string) => {
    return `function_ids%5B%5D=${id}`;
  }).join('&');
  if (functionIds) {
    result += `&${functionIds}`;
  }

  return result;
};
