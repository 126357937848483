import React from 'react';
import { Switch } from 'react-router';
import Route, { PermissionRoute } from '../../common/components/route';
import RouteNotFound from '../../common/components/route-not-found';
import SurveysContainer from './containers/surveys';
import EditSurveyContainer from './containers/edit-survey';
import PublishSurveyContainer from './containers/publish-survey';
import SurveyContainer from './containers/survey';
import EditPulseSurvey from './components/pulse-surveys/edit-pulse-survey';
import { EComponentTypes } from '@common/definitions';

require('./styles.scss');

const SurveyTemplate = () => (
  <Switch>
    <Route
      exact
      path={[
        '/admin/surveys/filter/:filter',
        '/admin/surveys',
        '/admin/surveys/pulse-overview'
      ]}
      component={SurveysContainer}
    />
    <PermissionRoute
      permission={{ component: EComponentTypes.PULSE_SURVEYS }}
      path="/admin/surveys/pulse/:surveyId/edit"
      component={EditPulseSurvey}
      exact={false}
    />
    <Route
      path={['/admin/surveys/:id/edit/screens/:screenId', '/admin/surveys/:id/edit']}
      component={EditSurveyContainer}
    />
    <Route path={['/admin/surveys/:id/publish']} component={PublishSurveyContainer} />
    <Route path="/admin/surveys/:id" component={SurveyContainer} />
    <Route component={RouteNotFound} />
  </Switch>
);

export default SurveyTemplate;
