import * as React from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';

import Overview from '@common/components/overview';
import List from '@common/components/list';
import { pageWrapper, EEventNames } from '../../../../../client/analytics';
import { FormAnswer } from '../../../forms/components/answer';
import * as surveySelector from '../../selectors/surveys';

const ResponseDetail = ({ survey, response, match: { params }, fetchResponse }) => {
  React.useEffect(() => {
    fetchResponse(survey.id, params.id);
  }, [params.id]);

  return (
    <Overview.Content className="Survey__Response">
      {response && (
        <List
          containerClassName="Response__Answers"
          items={response.answers}
          header={(
            <div className="Response__Header">
              <h1 className="fs-exclude">
                <Trans
                  i18nKey={response.user ? 'survey:survey_response_title' : 'survey:survey_response_title_anonymous'}
                  values={{
                    name: response.user?.full_name,
                  }}
                />
              </h1>
            </div>
          )}
          renderRow={FormAnswer}
        />
      )}
    </Overview.Content>
  );
};

const mapStateToProps = (state, { match }) => ({
  survey: surveySelector.item(state, match.params.surveyId),
  response: state.survey.responses[match.params.id],
});

const mapDispatchToProps = {
  fetchResponse: require('../../actions').fetchResponse,
};

export default connect(mapStateToProps, mapDispatchToProps)(pageWrapper(EEventNames.VISITED_SURVEY_RESPONSE_DETAIL_PAGE)(ResponseDetail));
