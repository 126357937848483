import { Audience, Predicate } from '@common/types/objects';
import { EPulseSurveyResultTypes, EPulseSurveyCategories, ESurveyTypes, ESurveyStatus } from '../definitions';
import { ScreenComponentItem } from '@modules/learning/types/objects';
import { APIQuestion } from '@modules/forms/types';
import { ESearchPredicateTypes, EStatus } from '@common/definitions';

export type EmployeeSurvey = {
  assigned_at: null | string;
  completed_at: null | string;
  created_at: string;
  id: string;
  organisation_id: string;
  question_count: 1;
  settings: {
    expires_at: null | string;
    is_anonymous: boolean;
    publish_at: null | string;
  };
  state: 'open';
  title: string;
  survey_type: 'direct' | 'ongoing';
  updated_at: null | string;
};

export type APISurveyScreen = {
  components: ScreenComponentItem[];
  id: string;
  index: number;
  is_published: boolean;
  question: APIQuestion | null;
  type: 'screen';
};

export type EmployeeSurveyDetail = EmployeeSurvey & {
  screens: APISurveyScreen[];
};

export type SurveyPredicate = Predicate & {
  context: any;
  value: any;
};

export type Survey = {
  archived_at: string | undefined;
  assigned_count: number;
  audience: {
    predicate_type: ESearchPredicateTypes;
    predicates: SurveyPredicate[];
  };
  created_at: string;
  deleted_at: string | undefined;
  id: string;
  organisation_id: string;
  responded_count: number;
  settings: {
    is_anonymous: boolean;
    publish_at: string | undefined;
    expires_at: string | undefined;
  };
  status: EStatus | ESurveyStatus;
  title: string | undefined;
  type: 'direct' | 'ongoing';
  survey_type: typeof ESurveyTypes[keyof typeof ESurveyTypes];
  created_by: string;
};

export type PulseSurveyQuestionTranslation = {
  language_code: string;
  text: string;
};

export type PulseSurveyQuestion = {
  id: string;
  category: string;
  organisation_id: string | null;
  is_custom: boolean;
  created_at: string;
  text: string;
  translations: PulseSurveyQuestionTranslation[];
};


export type PulseSurveySettings = {
  frequency: '1d' | '2d' | '3d' | '4d' | '5d' | '6d' | '1w' | '2w' | '3w' | '4w';
  questions_per_round: number;
};

export type PulseSurveyQuestionSelection = {
  question_pool_id: string;
  selected_languages: string[];
  id?: string;
  created_at?: string;
  pulse_survey_id?: string;
};

type PulseSurveyTimeSerie = {
  date: string;
  value: number;
};

type PulseSurveyAnalytic = {
  name: string;
  value: number;
  change: number;
  time_series: {
    current: PulseSurveyTimeSerie[];
    past: PulseSurveyTimeSerie[];
  };
};

type PulseSurveyAudience = Audience & {
  member_count: number;
};

export type PulseSurvey = {
  id: string;
  organisation_id: string;
  created_by: string;
  created_at: string;
  archived_at: string;
  deleted_at: string;
  name: string;
  status: EStatus | EPulseSurveyStatus;
  audience: PulseSurveyAudience;
  settings: PulseSurveySettings;
  questions: PulseSurveyQuestionSelection[];
  analytics: PulseSurveyAnalytic[];
};

type PulseSurveyMetricName = (
  `${EPulseSurveyResultTypes}` |
  typeof EPulseSurveyCategories[keyof typeof EPulseSurveyCategories]
);

type DistributionEntry = {
  count: number;
  percentage: number;
};

type TimeSeriesEntry = {
  date: string;
  value: number;
};

type RegularPulseSurveyMetric = {
  change: number;
  name: Omit<PulseSurveyMetricName, EPulseSurveyResultTypes.ENPS>;
  value: number;
  time_series: {
    current: TimeSeriesEntry[];
    past: TimeSeriesEntry[];
  }
};

export type ENPSPulseSurveyMetric = RegularPulseSurveyMetric & {
  name: EPulseSurveyResultTypes.ENPS;
  distribution: {
    detractors: DistributionEntry;
    passives: DistributionEntry;
    promoters: DistributionEntry;
    skipped: DistributionEntry;
  };
};

export type PulseSurveyMetric = RegularPulseSurveyMetric | ENPSPulseSurveyMetric;

export type PulseSurveyResponse = {
  data: PulseSurvey;
};

export enum EPulseSurveyStatus {
  PAUSED = 'paused',
}

export type AnswerStatistic = {
  step: number;
  answer_count: number;
};

export type PulseSurveyAnswerStatistic = {
  value: number;
  count: number;
};


