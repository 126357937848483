import React, { memo, useCallback, useState } from 'react';
import { Trans } from 'react-i18next';
import moment from 'moment';

import { TopNavigationBar } from '@common/components/navigation-bar';
import Overview from '@common/components/overview';
import { AsyncTable, TableDropdown } from '@common/components/table';
import AsyncList from '@common/components/list/async';
import Permission from '@common/components/permission';
import Placeholder from '@common/components/placeholder';
import ImageItemUser from '@common/components/image-item/user';
import { Button } from '@common/components/button';
import { Alert } from '@common/components/alert';
import Bar from '@common/components/bar';
import Confirm from '@common/components/confirm-button';
import ButtonTemplate from '@common/components/button-template';
import Container from '@common/components/container';
import { AlertService } from '@common/services/alert';
import SurveyType from '../../components/survey-type';
import UpsellModal from '@common/components/upsell-modal';
import Badge from '@common/components/badge';

import { useEncodedFullPath } from '@common/hooks/url';
import { useIsAvailableInPlanPackage } from '@common/hooks/use-is-available-in-plan-package';

import { APIFormTemplate } from '@modules/forms/types';
import { EPermissions, EComponentTypes, EPlanPackageConfig, EStatus } from '@common/definitions';
import { ESurveyTypes, ESurveyStatus } from '../../definitions';
import { getSurveyListColumns } from '@modules/survey/utils';

const CustomSurveys = memo(({
  history,
  organisation,
  surveys,
  templates,
  counts,
  match,
  duplicateSurvey,
  t,
  ...props
}: any) => {
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const loaded = typeof counts.total_count === 'number';
  const currentSurveyCount = loaded && counts.total_count;
  const {
    isAvailable: canCreateSurveys, showUpgradeModal,
  } = useIsAvailableInPlanPackage(EPlanPackageConfig.SURVEYS, currentSurveyCount);

  const statusLookup = {
    all: {
      filter: false,
      count: counts.total_count && counts.total_count - counts.archived_count,
    },
    draft: {
      filter: EStatus.DRAFT,
      count: counts.draft_count,
    },
    live: {
      filter: EStatus.LIVE,
      count: counts.live_count,
    },
    completed: {
      filter: ESurveyStatus.COMPLETED,
      count: counts.completed_count,
    },
    archived: {
      filter: EStatus.ARCHIVED,
      count: counts.archived_count,
    },
    scheduled: {
      filter: ESurveyStatus.SCHEDULED,
      count: counts.scheduled_count,
    },
  };

  // Map url to status
  // @ts-expect-error
  const currentStatus = statusLookup[match.params.filter] || statusLookup.all;

  const encodedPath = useEncodedFullPath();

  const handleOpenSurvey = (item: any) => {
    if ([EStatus.DRAFT, ESurveyStatus.SCHEDULED].includes(item.status))
      return history.push(`/admin/surveys/${item.id}/edit?back=${encodedPath}`);

    history.push(`/admin/surveys/${item.id}/summary?back=${encodedPath}`);
  };

  const handleCreateSurvey = async (templateId?: string) => {
    try {
      const { item } = await props.createSurvey(null, templateId);

      history.push(`/admin/surveys/${item.id}/edit?back=${encodedPath}`);
      AlertService.success(t('survey:form_survey_created'));
    } catch (error: any) {
      return AlertService.forStatus(error.status_code, {
        warning: t('survey:form_survey_create_warning'),
        error: t('survey:form_survey_create_error'),
      });
    }
  };

  const handleDuplicateSurvey = async (surveyId: string) => {
    try {
      const { item } = await duplicateSurvey(surveyId);

      history.push(`/admin/surveys/${item.id}/edit`);
      AlertService.success(t('survey:form_survey_duplicated'));
    } catch (response) {
      // @ts-expect-error
      return AlertService.forStatus(response.status_code, {
        warning: t('survey:form_survey_duplicate_warning'),
        error: t('survey:form_survey_duplicate_error'),
      });
    }
  };

  const limit = 1;
  const isOverLimit = counts.total_count >= limit;
  const isPremium = organisation.enabled_components.includes(EComponentTypes.SURVEYS_PREMIUM);

  const goToSurveyCreation = useCallback(() => {
    const base = '/admin/surveys/create/edit';
    history.push(`${base}?back=${encodedPath}`);
  }, [encodedPath, history]);

  return (
    <>
      <UpsellModal
        title={t('survey:surveys_pro')}
        video="https://www.youtube.com/watch?v=NdRI0nei5mU"
        intercom={{ buttonText: t('survey:upgrade_to_pro') }}
        show={showPremiumModal}
        onHide={() => setShowPremiumModal(false)}
      >
        <ul className="PremiumSurveyModal__Features">
          {['unlimited_surveys', 'ongoing_surveys', 'export_csv_file', 'target_audience', 'compare'].map((key) => (
            <li key={key} className="PremiumSurveyModal__Features__Item">
              <Trans i18nKey={`survey:premium_${key}`} />
            </li>
          ))}
        </ul>
      </UpsellModal>
      <TopNavigationBar
        title={[
          t('survey:filter', { context: match.params.filter || 'all' }),
          currentStatus.count !== undefined ? ` (${currentStatus.count})` : null,
          <a href={t('survey:learn_more')} target="_blank" rel="noopener noreferrer">{t('core:learn_more')}</a>,
        ]}
        action={(
          <Permission name={EPermissions.ORGANISATION_SURVEYS_CREATE}>
            <Button
              type="primary"
              size="large"
              iconRight="add"
              onClick={canCreateSurveys ? goToSurveyCreation : showUpgradeModal}
              disabled={!loaded || (!isPremium && isOverLimit)}
            >
              <Trans i18nKey="survey:create_survey" />
            </Button>
          </Permission>
        )}
      />
      <Container.Content>
        {
          loaded && !isPremium && (
            <Alert type={isOverLimit ? 'warning' : 'info'}>
              <Trans
                i18nKey="survey:survey_limit"
                values={{ count: limit }}
                components={[<a onClick={() => setShowPremiumModal(true)} role="button" />]}
              />
            </Alert>
          )
        }
        {(loaded && (isPremium || !isOverLimit)) && (
          <>
            <Bar>
              <h2><Trans i18nKey="survey:create_a_survey" /></h2>
            </Bar>
            <Overview>
              <Overview.Content>
                <AsyncList
                  disableInitialFetch={templates.length > 0}
                  containerClassName="SurveyTemplates"
                  items={templates}
                  // @ts-expect-error
                  data={{
                    onFetch: props.fetchTemplates,
                  }}
                  header={(
                    <ButtonTemplate
                      title="survey:create_survey"
                      onClick={canCreateSurveys ? goToSurveyCreation : showUpgradeModal}
                    />
                  )}
                  renderRow={({ item }) => (
                    <ButtonTemplate
                      item={item as APIFormTemplate}
                      onClick={canCreateSurveys ? handleCreateSurvey : showUpgradeModal}
                    />
                  )}
                />
              </Overview.Content>
            </Overview>
          </>
        )}
        <Overview>
          {/* @ts-expect-error */}
          <AsyncTable
            columns={getSurveyListColumns(t, match.params.filter)}
            items={surveys}
            data={{
              useCursor: true,
              onFetch: props.fetchSurveys,
              filter: {
                status: currentStatus.filter || undefined,
              },
            }}
            renderRow={({ item }: any) => ([
              !currentStatus.filter && <Badge status={item.status} />,
              <a role="button" onClick={() => handleOpenSurvey(item)}>
                {item.title || <small><Trans i18nKey="survey:survey_item_no_title" /></small>}
              </a>,
              <SurveyType survey={item} />,
              <span>{item.responded_count}</span>,
              item.created_by && <ImageItemUser item={item.created_by} />,
              moment(item.created_at).format('D MMM YYYY'),
              item.settings.publish_at
                ? moment(item.settings.publish_at).format('D MMM YYYY')
                : <small><Trans i18nKey="survey:no_publish_date" /></small>,
            ].filter((column) => column !== false))}
            placeholder={(
              <Placeholder
                title={
                  t('survey:no_surveys', {
                    context: currentStatus.filter ? 'filter' : undefined,
                    status: t(`survey:filter_${match.params.filter}`).toLowerCase()
                  })
                }
              />
            )}
            ActionComponent={({ item }) => (
              <TableDropdown id={`channel-${item.id}`}>
                {item.status === EStatus.DRAFT && (
                  <TableDropdown.Item onClick={() => handleOpenSurvey(item)}>
                    <Trans i18nKey="survey:survey_item_actions_edit" />
                  </TableDropdown.Item>
                )}
                <TableDropdown.Item
                  disabled={!loaded || (!isPremium && isOverLimit)}
                  onClick={canCreateSurveys ? () => handleDuplicateSurvey(item.id) : showUpgradeModal}
                >
                  <Trans i18nKey="survey:survey_item_actions_duplicate" />
                </TableDropdown.Item>
                {item.status === EStatus.LIVE && item.survey_type === ESurveyTypes.ONGOING && (
                  <TableDropdown.Item onClick={() => props.completeSurvey(item.id, currentStatus.filter)}>
                    <Trans i18nKey="survey:survey_item_actions_complete" />
                  </TableDropdown.Item>
                )}
                {item.status !== EStatus.ARCHIVED && (
                  <TableDropdown.Item onClick={() => props.archiveSurvey(item.id, currentStatus.filter)}>
                    <Trans i18nKey="survey:survey_item_actions_archive" />
                  </TableDropdown.Item>
                )}
                <Permission name={EPermissions.ORGANISATION_SURVEYS_REMOVE}>
                  <Confirm
                    title={t('survey:survey_item_actions_delete_confirm')}
                    onConfirm={() => props.deleteSurvey(item.id, item.status)}
                  >
                    <TableDropdown.Item danger>
                      <Trans i18nKey="survey:survey_item_actions_delete" />
                    </TableDropdown.Item>
                  </Confirm>
                </Permission>
              </TableDropdown>
            )}
          />
        </Overview>
      </Container.Content>
    </>
  );
});

export default CustomSurveys;
