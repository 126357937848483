import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { getHeightOfFirstElement } from '@common/utils/dom';
import { Column } from '../questions-editor';
import { useTranslation } from 'react-i18next';
import { AreaChart } from '@common/components/chart';
import { Button } from '@common/components/button';
import { Icon, IconTypes } from '@common/components/icon';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { parseSearchParams } from '@common/hooks';
import CategoryCompareModal from './category-compare-modal';
import { Link } from 'react-router-dom';
import { selected } from '@modules/organisation/selectors/organisation';
import { useSelector } from 'react-redux';
import { EPulseSurveyCategories, EPulseSurveyResultTypes } from '@modules/survey/definitions';
import { SimpleScoreCircleChart, ChartBadge } from './centered-circle-chart';
import { getCategoryLimitDates } from './utils';
import {
  useAreaChartData, useAreaChartValues
} from './overall-engagement-score';
import Tooltip from './tooltip';
import { useQueryParam } from '@common/hooks/url';
import { PromotersCircleChart, usePromotersCircleChartData } from './promoters';

type CategoryBreakdownProps = {
  title: string;
  id: string;
  titleIconType: string;
  data: Record<string, any>;
};

const CategoryBreakdown = memo(({
  id,
  title,
  titleIconType,
  data
}: CategoryBreakdownProps) => {

  const { t } = useTranslation();
  const { url } = useRouteMatch();

  const areaChartValues = useAreaChartValues(
    data.time_series.past,
    data.time_series.current
  );
  const areaChartData = useAreaChartData(data);

  // console.log("debug data", data);

  const { startDate, endDate } = getCategoryLimitDates(data);

  const [rateLabel, chartClass] = usePromotersCircleChartData(data.value);

  return (
    <Column id={id}>
      <Icon size={23} type={titleIconType as IconTypes} />
      <h2>{ title }</h2>

      <div className="charts">
        <div className="circleChart">
          {
            id === EPulseSurveyResultTypes.ENPS ?
            (
              <div className="enpsScore">
                <div>
                  <PromotersCircleChart score={data.value} className={chartClass} />
                </div>
                <div className="indicators">
                  <ChartBadge
                    rateLabel={rateLabel}
                    scoreChange={data.change}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </div>
              </div>
            ) : (
              <SimpleScoreCircleChart
                score={data.value}
                scoreChange={data.change}
                startDate={startDate}
                endDate={endDate}
              />
            )
          }
        </div>
        <div className="areaChart">
          <AreaChart
            legend
            data={areaChartData}
            values={areaChartValues}
            XAxisDataKey="date"
            tint="#5856D6"
            CustomTooltip={
              <Tooltip tooltipValueLabel={t('survey:pulse_survey_score')} />
            }
          />
        </div>
      </div>

      <div className="bottomBar">
        {/* <Button size="large" type="white" iconRight="chat" noFilledIcon>
          { t('survey:pulse_view_employee_feedback') }
        </Button> */}

        <Link to={`${url}?category=${data.name}&group=networks`}>
          <Button
            size="large"
            type="white"
            iconRight="arrows_diagrams"
            noFilledIcon
          >
            { t('survey:pulse_compare_score') }
          </Button>
        </Link>

        {/* <Link to={`${url}/${data.name}/questions`}>
          <Button iconRight="chevron_right" type="blue" size="large">
            { t('survey:pulse_see_related_questions') }
          </Button>
        </Link> */}
      </div>
    </Column>
  );
});


const CATEGORIES = [
  EPulseSurveyResultTypes.ENPS,
  EPulseSurveyCategories.ALIGNMENT,
  EPulseSurveyCategories.AMBASSADORSHIP,
  EPulseSurveyCategories.HAPPINESS,
  EPulseSurveyCategories.INCLUSION,
  EPulseSurveyCategories.PERSONAL_GROWTH,
  EPulseSurveyCategories.RECOGNITION_AND_FEEDBACK,
  EPulseSurveyCategories.RELATIONSHIP_WITH_MANAGER,
  EPulseSurveyCategories.SATISFACTION,
  EPulseSurveyCategories.TEAMWORK,
  EPulseSurveyCategories.WELLNESS
];

type CategoriesBreakdownProps = {
  predicates: any[];
  data: Record<string, any>;
};

const CategoriesBreakdown = memo(({
  predicates,
  data
}: CategoriesBreakdownProps) => {

  const { t } = useTranslation();

  const { search } = useLocation();

  const [category, group] = useMemo(() => {
    const params = parseSearchParams<Record<string, string>>(search);
    return [
      params.category || null,
      params.group || null
    ];
  }, [search]);

  const { url, params }: any = useRouteMatch();
  const history = useHistory();
  const closeCategoryModal = useCallback(() => {
    history.push(url);
  }, [url]);

  const orgId = useSelector(selected).id;

  const focusedCategory = useQueryParam('name');

  useEffect(() => {
    if (focusedCategory) {
      const categoryBreakdown = document.getElementById(focusedCategory);
      const topNavigation = getHeightOfFirstElement('TopNavigationBar');
      const topBar = getHeightOfFirstElement('TopBar');
      const margin = 10;
      if (
        categoryBreakdown &&
        typeof topNavigation === 'number' && typeof topBar === 'number'
      ) {
        const y = categoryBreakdown.offsetTop - topBar - topNavigation - margin;
        return window.scrollTo(0, y);
      }
    }
    window.scrollTo(0, 0);
  }, [focusedCategory]);

  return (
    <>
      {
        CATEGORIES.map((categoryName: string) => {
          const categoryData = data.metrics.find((metric: any) => {
            return metric.name === categoryName;
          });
          if (!categoryData) return null;

          return (
            <CategoryBreakdown
              key={categoryName}
              id={categoryName}
              titleIconType="favorite"
              title={t(`survey:pulse_survey_category_${categoryName}`)}
              data={categoryData}
            />
          );
        })
      }

      <CategoryCompareModal
        show={!!(category && group)}
        category={category || ''}
        group={group || ''}
        onHide={closeCategoryModal}
        surveyId={params.surveyId}
        organisationId={orgId}
        predicates={predicates}
      />
    </>
  );
});

export default CategoriesBreakdown;
