import { Icon, IconTypes } from '@common/components/icon';
import React, { memo, PropsWithChildren, ReactNode, useMemo } from 'react';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import './centered-circle-chart.scss';
import { formatDate } from './utils';
import { useTranslation } from 'react-i18next';

type Slice = {
  name: string;
  value: number;
  color?: string;
};

type CenteredCircleChartProps = PropsWithChildren<{
  id?: string;
  data: Slice[];
  indicators?: ReactNode;
  className?: string;
}>;

const CenteredCircleChart = memo(({
  children,
  id,
  data,
  indicators,
  className
}: CenteredCircleChartProps) => {

  return (
    <div id={id} className="CenteredCircleChart">
      <ResponsiveContainer
        className={`CircleChart reverse${className ? ` ${className}` : ''}`}
        width={200}
        height={200}
      >
        <PieChart width={400} height={400}>
          <Pie
            data={data}
            startAngle={90}
            endAngle={450}
            cx="50%"
            cy="50%"
            innerRadius={75}
            outerRadius={80}
            dataKey="value"
          />
        </PieChart>
      </ResponsiveContainer>
      <div className="count">
        { children }
      </div>
      {
        indicators && (
          <div className="indicators">
            { indicators }
          </div>
        )
      }
    </div>
  );
});

type ChartBadgeProps = {
  scoreChange: number;
  startDate: Date | string;
  endDate: Date | string;
  unit?: 'pt' | '%';
  rateLabel: string;
};

export const ChartBadge = memo(({
  scoreChange, startDate, endDate, rateLabel, unit
}: ChartBadgeProps) => {
  return (
    <>
      <h2>{ rateLabel }</h2>
      <div>
        {
          scoreChange !== 0 && (
            <>
              <Icon
                type={`arrow_${scoreChange > 0 ? 'up' : 'down'}` as IconTypes}
                size={22}
                className={scoreChange > 0 ? 'up' : 'down'}
              />
              <h2>{ scoreChange } { unit }</h2>
            </>
          )
        }
        <small>
          { formatDate(startDate) }{' - '}{ formatDate(endDate) }
        </small>
      </div>
    </>
  );
});

type SimpleScoreCircleChartProps = {
  maxScore?: number;
  score: number;
  scoreChange: number;
  startDate: Date;
  endDate: Date;
};

export const SimpleScoreCircleChart = memo(({
  maxScore = 5,
  score,
  scoreChange,
  startDate,
  endDate
}: SimpleScoreCircleChartProps) => {

  const data = [
    { name: 'a', value: score },
    { name: 'b', value: maxScore - score },
  ];

  const { t } = useTranslation();
  const [rateLabel, chartClass] = useMemo(() => {
    if (score <= 2) {
      return [t('survey:pulse_score_bad'), 'red'];
    }
    if (score <= 3) {
      return [t('survey:pulse_score_neutral'), 'gray'];
    }
    if (score <= 4) {
      return [t('survey:pulse_score_good'), ''];
    }
    if (score <= 5) {
      return [t('survey:pulse_score_very_good'), 'green2'];
    }
    return ['', undefined];
  }, [score, t]);

  return (
    <CenteredCircleChart
      data={data}
      className={chartClass}
      indicators={
        <ChartBadge
          scoreChange={scoreChange}
          rateLabel={rateLabel}
          startDate={startDate}
          endDate={endDate}
        />
      }
    >
      <h2>{score}</h2>
      <small>/{maxScore}</small>
    </CenteredCircleChart>
  );
});

export default CenteredCircleChart;
