import React, {
  ChangeEvent, Dispatch, ReactNode, SetStateAction, memo, useCallback, useState
} from 'react';
import ModalContent from '@common/components/modal/modal-content';
import { PulseSurveyResponse } from '@modules/survey/types/objects';
import { useTranslation } from 'react-i18next';
import ModalWindow from '@common/components/modal/modal-window';
import { AlertService } from '@common/services/alert';
import { Api } from '@common/services/api';

type ContentProps = {
  survey: PulseSurveyResponse;
  onHide: () => void;
  orgId: string;
  setSurvey: Dispatch<SetStateAction<PulseSurveyResponse | null>>;
};

const Content = memo(({ survey, onHide, orgId, setSurvey }: ContentProps) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [name, setName] = useState(survey?.data?.name);

  const onNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }, [setName]);

  const surveyId = survey.data.id;
  const onConfirm = useCallback(async () => {
    if (!survey) return;

    const url = `/v1/organisations/${orgId}/pulse_surveys/${surveyId}`;
    try {
      setLoading(true);
      await Api.patch(url, { name });
      setSurvey((surv: any) => {
        return {
          ...surv,
          data: {
            ...surv?.data,
            name
          }
        };
      });
      onHide();
      AlertService.success(t('survey:form_survey_edited'));
    } catch (error: any) {
      AlertService.error(t('survey:survey_save_error'));
      throw error;
    } finally {
      setLoading(false);
    }
  }, [name, orgId, surveyId, t, setLoading, onHide, setSurvey]);

  return (
    <ModalContent
      title={t('survey:form_modal_update_survey')}
      onHide={onHide}
      confirmButtonText={t('common:save')}
      confirmButtonDisabled={name?.length <= 0}
      confirmButtonLoading={loading}
      onConfirm={onConfirm}
    >
      <label className="Form__Label" htmlFor="name">
        { t('survey:pulse_survey_name') }
      </label>
      <input
        className="Form__control"
        name="name"
        value={name}
        onChange={onNameChange}
        maxLength={60}
      />
    </ModalContent>
  );
});


type NameEditorModalProps = {
  survey: PulseSurveyResponse;
  trigger: ReactNode;
  orgId: string;
  setSurvey: Dispatch<SetStateAction<PulseSurveyResponse | null>>;
};

const NameEditorModal = memo(({
  survey, trigger, orgId, setSurvey
}: NameEditorModalProps) => {

  const [show, setShow] = useState(false);
  const onHide = useCallback(() => setShow(false), [setShow]);
  const onShow = useCallback(() => setShow(true), [setShow]);
  return (
    <ModalWindow
      trigger={trigger}
      show={show}
      onHide={onHide}
      onShow={onShow}
    >
      <Content
        survey={survey} onHide={onHide} orgId={orgId} setSurvey={setSurvey}
      />
    </ModalWindow>
  );
});

export default NameEditorModal;
