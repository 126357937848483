import React from 'react';
import { useTranslation } from 'react-i18next';

import { combineClassNames } from '@common/utils/combineClassNames';
import { Icon } from '@common/components/icon';

type Template = {
  id: string;
  title: string;
  color?: string;
  background_color?: string;
  icon?: string;
  thumbnail_path?: string;
};

type CreateButtonProps = {
  title?: string;
  item?: Template;
  onClick: (templateId?: string) => void;
};

const ButtonTemplate = ({
  title = '',
  item,
  onClick,
}: CreateButtonProps) => {
  const { t } = useTranslation();
  const className = combineClassNames('ButtonTemplate', {
    'ButtonTemplate--add': !item,
    'ButtonTemplate--clickable': !!item,
  });
  return (
    <div
      className={className}
      onClick={() => onClick(item?.id)}
      role="button"
    >
      <div
        className="ButtonTemplate__Card"
        style={{ backgroundColor: item?.color || item?.background_color }}
      >
        {(item && (
          <img
            alt={item.title}
            src={item.icon || item.thumbnail_path}
          />)) || (
          <Icon
            type="add"
          />
        )}
      </div>
      <div className="ButtonTemplate__Title">
        {(item && `+ ${item.title}`) || t(title)}
      </div>
    </div>
  );
};

export default ButtonTemplate;
