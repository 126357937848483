import React, {
  Dispatch, SetStateAction, memo, useEffect, useState, useMemo
} from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';

import FiltersToolbar from '@common/components/filters-toolbar/filters-toolbar';
import Spinner from '@common/components/spinner';
import OverallEngagementScore from './overall-engagement-score';
import PromotersInYourOrganization from './promoters';
import ParticipationRate from './participation-rate';
import CategoriesBreakdown from './categories-breakdown';
import { Button } from '@common/components/button';
import Placeholder from '@common/components/placeholder/placeholder';
import Breadcrumbs from '@common/components/breadcrumbs';

import { PulseSurveyMetric, PulseSurveyResponse } from '@modules/survey/types/objects';
import { Api } from '@common/services/api';
import { EPulseSurveyCategories, EPulseSurveyResultTypes } from '@modules/survey/definitions';
import { addParams } from './utils';

import './analytics.scss';

type CoreAnalyticsProps = {
  predicates: any[];
  orgId: string;
  survey: PulseSurveyResponse;
};

const CoreAnalytics = memo(({
  predicates,
  orgId,
  survey
}: CoreAnalyticsProps) => {
  const [details, setDetails] = useState<null | { data: any }>(null);
  const surveyId = survey.data?.id;
  useEffect(() => {
    setDetails(null);

    const analyticsUrl =
      `/v1/organisations/${orgId}/pulse_surveys/${surveyId}/analytics`;

    const metrics = [
      ...Object.values(EPulseSurveyResultTypes),
      ...Object.values(EPulseSurveyCategories)
    ].map((metric: string) => `metrics%5B%5D=${metric}`).join('&');

    const detailsUrl = addParams(`${analyticsUrl}/detailed`, predicates);
    Api.get(`${detailsUrl}&${metrics}`).then((res: any) => setDetails(res));
  }, [orgId, surveyId, setDetails, predicates]);

  const match = useRouteMatch();
  const { t } = useTranslation();

  const gotData = useMemo(() => {
    const metrics = details?.data?.metrics;
    if (!Array.isArray(metrics) || metrics.length <= 0) {
      return false;
    }
    const empty = metrics.find((metric: PulseSurveyMetric) => {
      return metric?.time_series?.current?.length <= 0;
    });
    return !empty;
  }, [details]);

  if (!details) {
    return <Spinner centered size="large" />;
  }
  if (!gotData) {
    return (
      <Placeholder
        title={t('survey:pulse_survey_no_data_for_search')}
        size="small"
        image="/static/images/placeholders/no-results.svg"
      />
    );
  }

  return (
    <Switch>
      <Route path={`${match.path}/categories`}>
        <CategoriesBreakdown
          predicates={predicates}
          data={details.data}
        />
      </Route>

      <Route>
        <OverallEngagementScore data={details.data} />
        <PromotersInYourOrganization data={details.data} />
        <ParticipationRate data={details.data} survey={survey} />
      </Route>
    </Switch>
  );
});

type AnalyticsProps = {
  survey: PulseSurveyResponse;
  organisation: any;
  predicates: any[];
  setPredicates: Dispatch<SetStateAction<any[]>>;
};

const Analytics = memo(({
  survey,
  organisation,
  predicates,
  setPredicates
}: AnalyticsProps) => {
  const match = useRouteMatch();
  const { t } = useTranslation();
  const { url, path } = match;
  const questionsPath = `${path}/categories/:categoryName/questions`;
  const questionsMatch: any = useRouteMatch(questionsPath);

  const crumbs = useMemo(() => {
    const categoryName = questionsMatch?.params.categoryName || '';
    return [{
      label: t('survey:pulse_analytics_breadcrumb_overview'),
      href: url
    }, {
      label: t('survey:pulse_analytics_breadcrumb_categories'),
      href: `${url}/categories`
    }, {
      label: t('survey:pulse_analytics_breadcrumb_questions', {
        category: t(`survey:pulse_survey_category_${categoryName}`)
      }),
      href: `${url}/categories/${categoryName}/questions`,
    }];
  }, [url, questionsMatch, t]);

  return (
    <div className="Analytics">
      <h2>{ t('survey:pulse_survey_tab_analytics') }</h2>
      <Breadcrumbs crumbs={crumbs} />

      <div className="topBar">
        <Switch>
          <Route exact path={`${match.path}/categories`}>
            <Link to={match.url}>
              <Button icon="arrow_left" type="white" size="large">
                { t('survey:pulse_back_to_overview') }
              </Button>
            </Link>
          </Route>
        </Switch>

        <FiltersToolbar
          predicates={predicates}
          setPredicates={setPredicates}
          searchEnabled={false}
          organisation={organisation}
        />
      </div>

      <Switch>
        <Route>
          <CoreAnalytics
            orgId={organisation.id} survey={survey} predicates={predicates}
          />
        </Route>
      </Switch>
    </div>
  );
});

export default Analytics;
