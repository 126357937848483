import React, {
  Dispatch, PropsWithChildren, SetStateAction, memo, useCallback, useEffect,
  useState, useMemo
} from 'react';
import { useRouteMatch, Route } from 'react-router';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { TopNavigationBar } from '@common/components/navigation-bar/top-navigation-bar';
import Container from '@common/components/container';
import { Button } from '@common/components/button';
import Spinner from '@common/components/spinner';
import { StoreState } from '@common/types/store';
import { Api } from '@common/services/api';
import { Select } from '@common/components/form/inputs/select';
import { AlertService } from '@common/services/alert';
import { EPredicateFilters } from '@common/components/predicates-filter';
import { EPredicateFields, EPredicateOperators, EStatus } from '@common/definitions';
import * as orgSelectors from '@modules/organisation/selectors/organisation';
import { EPulseSurveyStatus, PulseSurveyQuestion, PulseSurveyResponse } from '@modules/survey/types/objects';

import Analytics from './analytics/analytics';
import QuestionsEditor from './questions-editor';
import NameEditorModal from './name-editor-modal';
import SettingsEditor from './settings-editor';

import './edit-pulse-survey.scss';

type PulseSurveyTopNavBarProps = {
  survey: PulseSurveyResponse;
  orgId: string;
  setSurvey: Dispatch<SetStateAction<PulseSurveyResponse | null>>;
};

const PulseSurveyTopNavBar = memo(({
  survey,
  orgId,
  setSurvey
}: PulseSurveyTopNavBarProps) => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const status = survey?.data?.status;

  const statusOptions = useMemo(() => {
    return [{
      value: EStatus.DRAFT,
      label: t('common:status_draft')
    }, {
      value: EStatus.LIVE,
      label: t('common:status_live')
    }, {
      value: EPulseSurveyStatus.PAUSED,
      label: t('common:status_paused')
    }, {
      value: EStatus.ARCHIVED,
      label: t('common:status_archived')
    }];
  }, [t]);

  const onStatusChange = useCallback(async (selected: any) => {
    const url = `/v1/organisations/${orgId}/pulse_surveys/${survey.data.id}`;
    try {
      await Api.patch(url, { status: selected.value });
      AlertService.success(t('survey:form_survey_edited'));
      setSurvey((surv: any) => {
        return {
          ...surv,
          data: {
            ...surv?.data,
            status: selected.value
          }
        };
      });
    } catch (error: any) {
      AlertService.error(t('survey:survey_save_error'));
      throw error;
    }
  }, [orgId, survey, t, setSurvey]);

  const tabs = useMemo(() => {
    return [{
      name: t('survey:pulse_survey_tab_questions'),
      to: `${match.url}/questions`,
    }, {
      name: t('survey:survey_tabs_settings'),
      to: `${match.url}/settings`,
    }, (
      EStatus.DRAFT !== status ?
        {
          name: t('survey:pulse_survey_tab_analytics'),
          to: `${match.url}/analytics`,
        } :
        undefined
    )].filter((value: any) => !!value);
  }, [t, match, status]);

  return (
    <TopNavigationBar
      breadcrumbs={[
        {
          name: t('survey:pulse_surveys_sidebar_subtitle'),
          path: '/admin/surveys/pulse-overview'
        },
        { name: survey.data?.name }
      ]}
      form={Div}
      title={survey && (
        <NameEditorModal
          survey={survey}
          trigger={survey.data?.name}
          orgId={orgId}
          setSurvey={setSurvey}
        />
      )}
      badge={survey?.data?.status}
      tabs={tabs}
      action={(
        <>
          <Select
            clearable={false}
            className="PulseSurveyStatusSelector"
            options={statusOptions}
            value={survey.data.status}
            onChange={onStatusChange}
          />
          <Link to="/admin/surveys/pulse-overview">
            <Button size="large">
              {t('forms:go_back')}
            </Button>
          </Link>
        </>
      )}
    />
  );
});

const Div = memo(({ children }: PropsWithChildren<{}>) => {
  return <div>{children}</div>;
});

const useAnalyticsPredicates = (networks: any[], functions: any[]) => {
  return useState(() => {
    return [{
      filter: EPredicateFilters[EPredicateFields.DATE],
      value: {
        attribute: EPredicateFields.DATE,
        comparison: EPredicateOperators.BETWEEN,
        value: [moment().subtract(30, 'd').toDate(), new Date()]
      },
      options: undefined,
      scrolling: false
    }, {
      filter: EPredicateFilters[EPredicateFields.NETWORK],
      value: {
        attribute: EPredicateFields.NETWORK,
        comparison: EPredicateOperators.IN,
        value: []
      },
      options: networks,
    }, {
      filter: EPredicateFilters[EPredicateFields.FUNCTION],
      value: {
        attribute: EPredicateFields.FUNCTION,
        comparison: EPredicateOperators.IN,
        value: []
      },
      options: functions,
    }];
  });
};

type PulseSurveyQuestionsResponse = {
  data: PulseSurveyQuestion[];
};

const EditPulseSurvey = memo(() => {
  const match = useRouteMatch();

  const org = useSelector((s: StoreState) => orgSelectors.selected(s));
  const orgId = org.id;

  const networks = useSelector((s: StoreState) => orgSelectors.networks(s));
  const functions = useSelector((s: StoreState) => orgSelectors.functions(s));

  const [analyticsPredicates, setAnalyticsPredicates] =
    useAnalyticsPredicates(networks, functions);

  const [questions, setQuestions] = useState<null | PulseSurveyQuestionsResponse>(null);
  const [survey, setSurvey] = useState<null | PulseSurveyResponse>(null);

  // @ts-expect-error
  const surveyId = match?.params?.surveyId;
  useEffect(() => {
    const questionsUrl = `/v1/organisations/${orgId}/pulse_surveys/questions`;
    Api.get(questionsUrl).then((response: any) => {
      setQuestions(response);
    });
    const surveyUrl = `/v1/organisations/${orgId}/pulse_surveys/${surveyId}`;
    Api.get(surveyUrl).then((response: any) => {
      setSurvey(response);
    });
  }, [orgId, setQuestions, setSurvey, surveyId]);

  const loading = !questions || !survey;


  return (
    <Container name="EditPulseSurvey">
      {
        survey && (
          <PulseSurveyTopNavBar
            survey={survey}
            setSurvey={setSurvey}
            orgId={orgId}
          />
        )
      }
      {
        loading ? (
          <Spinner className="pageSpinner" centered size="large" />
        ) : (
          <Container.Content>
            <Route path={`${match.path}/questions`}>
              <QuestionsEditor
                questions={questions.data}
                survey={survey}
                setSurvey={setSurvey}
                orgId={orgId}
              />
            </Route>
            <Route path={`${match.path}/settings`}>
              <SettingsEditor
                survey={survey}
                setSurvey={setSurvey}
                orgId={orgId}
              />
            </Route>
            <Route path={`${match.path}/analytics`}>
              <Analytics
                survey={survey}
                predicates={analyticsPredicates}
                setPredicates={setAnalyticsPredicates}
                organisation={org}
              />
            </Route>
          </Container.Content>
        )
      }
    </Container>
  );
});

export default EditPulseSurvey;
