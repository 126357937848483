import * as React from 'react';
import { Trans } from 'react-i18next';
import Icon from '../../../../common/components/icon';
import Tooltip from '../../../../common/components/tooltip';

const maxLabelLength = 30;

const concatLabel = (label) => {
  const string = label.toString();

  if (string.length > maxLabelLength) {
    return (
      <Tooltip title={string}>
        <span>
          {string.substring(0, maxLabelLength - 3)}
          ...
        </span>
      </Tooltip>
    );
  }

  return string;
};

const SurveyCompareTableComponent = ({ data, compareType, sortDescending = true, setSortDescending }) => {
  // If the answers are based on options or a number
  const hasOptions = !!data[0].answer_statistics[0].option;

  // Calculate width so we can force scrolling when needed and keep a minimum width for each data column
  const dataCellWidth = hasOptions
    ? (Math.max(100, 600 / data[0].answer_statistics.length))
    : (Math.max(65, 450 / data[0].answer_statistics.length));

  // Min width for the table container
  const minWidth = 400 + data[0].answer_statistics.length * (dataCellWidth + 32);

  // Get some total counts
  const total = data
    .filter((row) => row.answer_statistics)
    .reduce((acc, row) => {
      if (!row.network && !row.function) return acc;

      return {
        response_count: acc.response_count + row.question_response_count,
        answer_statistics: !acc.answer_statistics ? row.answer_statistics.map((r) => r.answer_count) : acc.answer_statistics.map((count, i) => count + row.answer_statistics[i].answer_count),
        count: acc.count + row.answer_statistics.reduce((subtotal, column) => subtotal + column.step * column.answer_count, 0),
      };
    }, {
      response_count: 0,
      answer_statistics: [],
      count: 0,
    });

  // Get the highest amount of votes per option
  total.maxCount = total.answer_statistics.reduce((acc, answerCount) => Math.max(acc, answerCount), 0);

  // Get the highest average of all rows
  total.average = Math.round(total.count / total.response_count * 10) / 10;
  total.maxAverage = data.reduce((acc, { average }) => Math.max(acc, average), 0);

  // Get most voted options
  const mostVotedOptions = hasOptions && data
    .reduce((acc, row) => {
      if (!acc) return row.answer_statistics;

      return acc.map((column, i) => ({
        option: column.option,
        answer_count: column.answer_count + row.answer_statistics[i].answer_count,
      }));
    }, null)
    .reduce((acc, column) => {
      if (!acc || column.answer_count > acc.answer_count) return column;

      return acc;
    }, null)
    .option;

  // Sort descending or ascending
  const sortDirection = (a, b) => (sortDescending ? b - a : a - b);

  const sortedData = data.sort((a, b) => {
    if (!hasOptions) return sortDirection(a.average, b.average);

    return sortDirection(a.question_response_count, b.question_response_count);
  });

  return (
    <div className="ReportCompare__Table__Container">
      <div className="Table ReportCompare__Table" style={{ minWidth }}>
        <div className="Table__Row Table__Header">
          <div className="Table__Cell" style={{ minWidth: 150 }}>
            <Trans i18nKey="survey:compare_type" values={{ context: compareType }} />
          </div>
          {data[0].answer_statistics.map(({ step, option }) => (
            <div key={option && option.id || step} className="Table__Cell ReportCompare__Table__DataCell">{concatLabel((option && option.value) || step)}</div>
          ))}
          <div className="Table__Cell">
            <Trans i18nKey={hasOptions ? 'survey:most_votes' : 'survey:average'} />
            {setSortDescending && (
              <a className="ReportCompare__Table__Sort" onClick={() => setSortDescending(!sortDescending)}>
                <Icon type={sortDescending ? 'arrow_drop_down' : 'arrow_drop_up'} />
              </a>
            )}
          </div>
        </div>
        {sortedData.map((row) => {
          const parent = row.network || row.function;

          if (!parent) return null;

          const average = Math.round(row.average * 10) / 10;

          return (
            <div className="Table__Row">
              <div className="Table__Cell ReportCompare__Table__LabelCell">
                {parent.name}
                <small className="ReportCompare__Table__ResponseCount">
                  <Trans i18nKey="survey:response_count" values={{ count: row.question_response_count }} />
                </small>
              </div>
              {row.answer_statistics.map(({ answer_count: answerCount }) => (
                (row.maxCount === answerCount && row.maxCount > 0) ? (
                  <div className="Table__Cell ReportCompare__Table__DataCell ReportCompare__Table__DataCell--highlight" style={{ width: dataCellWidth }}>{answerCount}</div>
                ) : (
                  <div className="Table__Cell ReportCompare__Table__DataCell" style={{ width: dataCellWidth }}>{answerCount}</div>
                )
              ))}
              {hasOptions
                ? (
                  <div className="Table__Cell ReportCompare__Table__Average">
                    {row.mostVotedOption ? concatLabel(row.mostVotedOption.option.value) : '-'}
                  </div>
                )
                : (average === total.maxAverage
                  ? <div className="Table__Cell ReportCompare__Table__Average ReportCompare__Table__DataCell--highlight">{average}</div>
                  : <div className="Table__Cell ReportCompare__Table__Average">{average}</div>
                )}
            </div>
          );
        })}
        <div className="Table__Row">
          <div className="Table__Cell" style={{ minWidth: 150 }}>
            <Trans i18nKey="survey:compare_total" />
            <small className="ReportCompare__Table__ResponseCount">
              <Trans i18nKey="survey:response_count" values={{ count: total.response_count }} />
            </small>
          </div>
          {total.answer_statistics.map((answerCount) => (
            (total.maxCount === answerCount && total.maxCount > 0) ? (
              <div className="Table__Cell ReportCompare__Table__DataCell ReportCompare__Table__DataCell--highlight">{answerCount}</div>
            ) : (
              <div className="Table__Cell ReportCompare__Table__DataCell">{answerCount}</div>
            )
          ))}
          <div className="Table__Cell ReportCompare__Table__Average">
            {mostVotedOptions && mostVotedOptions.value || total.average}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyCompareTableComponent;
