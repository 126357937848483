import React, { memo, useCallback } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import BootstrapModal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

import { Icon } from '@common/components/icon';
import { Button } from '@common/components/button';
import { AudienceInput } from '@common/components/form/audience';
import { EPlanPackageConfig } from '@common/definitions';
import { usePlanPackageAudienceHandlers } from '@common/hooks/use-plan-package-audience-handlers';

type AudienceModalProps = {
  close: () => void;
  onSubmit: (data: Record<string, any>) => void;
};

type Props = InjectedFormProps<{}, AudienceModalProps> & AudienceModalProps;

const AudienceModal = memo(({
  close, onSubmit, handleSubmit
}: Props) => {
  const { t } = useTranslation();
  const planPackageAudienceHandlers = usePlanPackageAudienceHandlers({
    packageIdAddFilters: EPlanPackageConfig.SURVEYS_AUDIENCE_SELECTOR,
    packageIdDaysInService: EPlanPackageConfig.SURVEYS_AUDIENCE_SELECTOR_ONBOARDING,
  });

  const submit = useCallback((data) => {
    onSubmit(data);
    close();
  }, [onSubmit, close]);

  return (
    // @ts-expect-error
    <BootstrapModal className="BootstrapModal" show size="large">
      <div className="Modal__Wrapper">
        <BootstrapModal.Header>
          <div className="Modal__MainHeader">
            <h2 className="Modal__title">
              {t('survey:select_audience')}
            </h2>
            <Icon className="Modal__HeaderClose" type="close" onClick={close} />
          </div>
        </BootstrapModal.Header>
        <BootstrapModal.Body>
          <form id="survey-settings-audience" onSubmit={handleSubmit(submit)}>
            <AudienceInput
              name="audience"
              tooltips={{ audience: t('survey:audience_selector_tooltip') }}
              addContextToPredicatesOnChange
              {...planPackageAudienceHandlers}
            />
          </form>
        </BootstrapModal.Body>
        <BootstrapModal.Footer>
          <div className="pull-right">
            <Button onClick={close}>
              { t('common:modal_footer_button_cancel') }
            </Button>
            <Button
              type="primary"
              buttonType="submit"
              form="survey-settings-audience"
            >
              { t('core:confirm') }
            </Button>
          </div>
        </BootstrapModal.Footer>
      </div>
    </BootstrapModal>
  );
});

const form = reduxForm<{}, AudienceModalProps >({ form: 'survey-settings-audience' });
const AudienceModalForm = form(AudienceModal);

export default AudienceModalForm;
