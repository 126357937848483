import * as React from 'react';
import ImageItem from './index';

export const ImageItemUser = ({ item: user, ...props }) => (
  <ImageItem
    mask
    image={user.profile_img}
    name={user.full_name}
    {...props}
  />
);

export default ImageItemUser;
