import ProportionChart
  from '@common/components/proportion-chart/proportion-chart';
import Tooltip from '@common/components/tooltip';
import React, { memo, useMemo, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

const getEnpsTooltip = (label: string, count: number) => {
  return memo(({ children }: PropsWithChildren<{}>) => {
    const { t } = useTranslation();
    return (
      <Tooltip placement="bottom" title={t(label, { count })}>
        { children }
      </Tooltip>
    );
  });
};

type EnpsChartProps = {
  detractors: number;
  passives: number;
  promoters: number;
  skipped: number;
};

export const detractorsColor = '#FF6242';
export const passivesColor = '#FFCC00';
export const promotersColor = '#34D399';
export const naOrSkippedColor = '#D5D9DE';

const EnpsChart = memo(({
  detractors, passives, promoters, skipped
}: EnpsChartProps) => {

  const bars = useMemo(() => {
    return [{
      color: detractorsColor,
      value: detractors,
      wrapper: getEnpsTooltip('survey:pulse_enps_tooltip_detractors', detractors)
    }, {
      color: passivesColor,
      value: passives,
      wrapper: getEnpsTooltip('survey:pulse_enps_tooltip_passives', passives)
    }, {
      color: promotersColor,
      value: promoters,
      wrapper: getEnpsTooltip('survey:pulse_enps_tooltip_promoters', promoters)
    }, {
      color: naOrSkippedColor,
      value: skipped,
      wrapper: getEnpsTooltip('survey:pulse_enps_tooltip_skipped', skipped)
    }];
  }, [detractors, promoters, passives]);

  return <ProportionChart className="EnpsChart" bars={bars} />;
});

export default EnpsChart;
