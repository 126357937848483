import { StoreState } from '@common/types/store';

const isFormValidById = (form: StoreState['form'], formId: string) => Object.keys(form).reduce((result, id) => {
  if (id.indexOf(formId) > -1) {
    const errors = form[id].syncErrors;
    if (errors) return false;
  }
  return result;
}, true);

export default isFormValidById;
