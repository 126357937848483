import React, {
  memo, useState, useMemo, useCallback,
  PropsWithChildren, Dispatch, SetStateAction
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Accordion, AccordionItem as Item } from '@szhsin/react-accordion';

import { Icon } from '@common/components/icon';
import ModalWindow from '@common/components/modal/modal-window';
import ModalContent from '@common/components/modal/modal-content';
import { Overview } from '@common/components/overview';
import { Container } from '@common/components/container';
import {
  PulseSurveyQuestion, PulseSurveyQuestionTranslation,
  PulseSurveyQuestionSelection, PulseSurveyResponse
} from '@modules/survey/types/objects';
import { Button } from '@common/components/button';
import { Api } from '@common/services/api';
import { AlertService } from '@common/services/alert';
import { EPulseSurveyCategories } from '@modules/survey/definitions';
import { Checkbox } from '@common/components/form/inputs/checkbox';

const AccordionItem = ({
  header, total, count, icon, ...rest
}: any) => {
  const { t } = useTranslation();
  return (
    <Item
      {...rest}
      header={
        <>
          <Icon type="expand_more" className="chevron-down" />
          <Icon type={icon} className="questionIcon" size={16} />
          <h3>{header}</h3>
          <h6 className="questionsCount">
            { t('survey:pulse_survey_questions_count', { count, total }) }
          </h6>
        </>
      }
    />
  );
};


type QuestionSettingsModalProps = PropsWithChildren<{
  translations: PulseSurveyQuestionTranslation[];
}>;

const QuestionSettingsModal = memo(({
  children,
  translations
}: QuestionSettingsModalProps) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const onHide = useCallback(() => setShow(false), [setShow]);
  const onShow = useCallback(() => setShow(true), [setShow]);

  const trans = useSelector((state: Record<string, any>) => state.language.translations);
   // const [boost, setBoost] = useState<boolean>(false);

  return (
    <ModalWindow
      trigger={children}
      show={show}
      onHide={onHide}
      onShow={onShow}
      className="PulseSurveyQuestionTranslationsModal"
    >
      <ModalContent
        title={t('survey:pulse_survey_question_settings')}
        onHide={onHide}
        hideConfirm
      >
        <Overview.Content>
          {/* TODO: link ticket to enable feature */}
          {/* <h3>{ t("survey:pulse_survey_boost") }</h3>
          <p>{ t("survey:pulse_survey_boost_description") }</p>
          <ToggleButton value={boost} label="" onChange={() => setBoost(!boost)} /> */}
          <h3 className="translationsTitle">
            { t('survey:pulse_survey_translations_modal_title') }
          </h3>
          <p>{ t('survey:pulse_survey_translations_modal_description') }</p>
          {
            translations.map((tr: PulseSurveyQuestionTranslation) => {
              const country = trans.find(({ locale }: any) => {
                return locale === tr.language_code;
              });
              return (
                <div key={tr.language_code} className="translation">
                  <div>
                    { country && <img src={country.icon} alt={country.name} /> }
                    { tr.text }
                  </div>
                </div>
              );
            })
          }
        </Overview.Content>
      </ModalContent>
    </ModalWindow>
  );
});

type AccordionCategoryItemProps = {
  header: string;
  questions: PulseSurveyQuestion[];
  survey: PulseSurveyResponse;
  icon: string;
  setSurvey: Dispatch<SetStateAction<PulseSurveyResponse | null>>;
  initialEntered?: boolean;
};

const AccordionCategoryItem = memo(({
  header,
  icon,
  questions,
  survey,
  setSurvey,
  initialEntered = false
}: AccordionCategoryItemProps) => {
  const count = questions.filter((question: PulseSurveyQuestion) => {
    return !!survey.data.questions?.find((q: PulseSurveyQuestionSelection) => {
      return q.question_pool_id === question.id;
    });
  }).length;

  return (
    <AccordionItem
      initialEntered={initialEntered}
      header={header}
      total={questions.length}
      count={count}
      icon={icon}
    >
      {
        questions.map(({ id, text, translations }: PulseSurveyQuestion) => {
          const checked = !!survey.data.questions.find((q: PulseSurveyQuestionSelection) => {
            return q.question_pool_id === id;
          });
          return (
            <div key={id} className="accordionQuestion">
              <Checkbox
                size="large"
                label={text}
                value={checked}
                onChange={(value) => {
                  setSurvey((surv: any) => {
                    const existingQuestions = surv?.data?.questions || [];
                    const updatedQuestionsList = value ?
                      [
                        ...existingQuestions,
                        {
                          question_pool_id: id,
                          selected_languages: translations.map(
                            (tr: PulseSurveyQuestionTranslation) => {
                              return tr.language_code;
                            }
                          )
                        }
                      ] :
                      existingQuestions.filter((q: PulseSurveyQuestionSelection) => {
                        return q.question_pool_id !== id;
                      });
                    return {
                      ...surv,
                      data: {
                        ...surv?.data,
                        questions: updatedQuestionsList
                      }
                    };
                  });
                }}
              />
              <QuestionSettingsModal translations={translations}>
                <Icon className="settings" type="settings" />
              </QuestionSettingsModal>
            </div>
          );
        })
      }
    </AccordionItem>
  );
});


type QuestionsAccordionProps = {
  questions: PulseSurveyQuestion[];
  survey: PulseSurveyResponse;
  setSurvey: Dispatch<SetStateAction<PulseSurveyResponse | null>>;
};

const QuestionsAccordion = memo(({
  questions,
  survey,
  setSurvey
}: QuestionsAccordionProps) => {
  const { t } = useTranslation();

  const accordionItems = useMemo(() => {
    const getQuestions = (category: string) => {
      return questions.filter((q: any) => q.category === category);
    };
    return [{
      header: t('survey:pulse_survey_category_personal_growth'),
      icon: 'plant',
      questions: getQuestions(EPulseSurveyCategories.PERSONAL_GROWTH)
    }, {
      header: t('survey:pulse_survey_category_recognition_and_feedback'),
      icon: 'speech_cloud',
      questions: getQuestions(EPulseSurveyCategories.RECOGNITION_AND_FEEDBACK)
    }, {
      header: t('survey:pulse_survey_category_teamwork'),
      icon: 'team',
      questions: getQuestions(EPulseSurveyCategories.TEAMWORK)
    }, {
      header: t('survey:pulse_survey_category_alignment'),
      icon: 'to_the_center',
      questions: getQuestions(EPulseSurveyCategories.ALIGNMENT)
    }, {
      header: t('survey:pulse_survey_category_wellness'),
      icon: 'heart_pulse',
      questions: getQuestions(EPulseSurveyCategories.WELLNESS)
    }, {
      header: t('survey:pulse_survey_category_relationship_with_manager'),
      icon: 'hierarchy',
      questions: getQuestions(EPulseSurveyCategories.RELATIONSHIP_WITH_MANAGER)
    }, {
      header: t('survey:pulse_survey_category_happiness'),
      icon: 'smile2',
      questions: getQuestions(EPulseSurveyCategories.HAPPINESS)
    }, {
      header: t('survey:pulse_survey_category_inclusion'),
      icon: 'heart_hands',
      questions: getQuestions(EPulseSurveyCategories.INCLUSION)
    }, {
      header: t('survey:pulse_survey_category_ambassadorship'),
      icon: 'cockade',
      questions: getQuestions(EPulseSurveyCategories.AMBASSADORSHIP)
    }, {
      header: t('survey:pulse_survey_category_satisfaction'),
      icon: 'sparks',
      questions: getQuestions(EPulseSurveyCategories.SATISFACTION)
    }];
  }, [questions, t]);

  return (
    <Accordion transition transitionTimeout={250}>
      {
        accordionItems.map((item: any, index: number) => {
          return (
            <AccordionCategoryItem
              icon={item.icon}
              key={item.header}
              header={item.header}
              questions={item.questions}
              survey={survey}
              setSurvey={setSurvey}
              initialEntered={index === 0}
            />
          );
        })
      }
    </Accordion>
  );
});


type ColumnProps = PropsWithChildren<{
  className?: string;
  id?: string;
}>;

export const Column = memo(({ children, className, id }: ColumnProps) => {
  return (
    <Overview id={id}>
      <Overview.Content className={className}>
        <Container.Column>
          { children }
        </Container.Column>
      </Overview.Content>
    </Overview>
  );
});


type QuestionsEditorProps = {
  questions: PulseSurveyQuestion[];
  survey: PulseSurveyResponse;
  setSurvey: Dispatch<SetStateAction<PulseSurveyResponse | null>>;
  orgId: string;
};

const QuestionsEditor = memo(({
  questions,
  survey,
  setSurvey,
  orgId
}: QuestionsEditorProps) => {

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const onSubmit = useCallback(async () => {
    if (!survey) return;

    const url = `/v1/organisations/${orgId}/pulse_surveys/${survey.data.id}`;
    try {
      setLoading(true);
      await Api.patch(url, {
        questions: survey.data.questions.map((q: PulseSurveyQuestionSelection) => {
          const question: any = {
            ...q,
            id: q.question_pool_id
          };
          delete question.pulse_survey_id;
          delete question.question_pool_id;
          delete question.created_at;
          return question;
        })
      });
      AlertService.success(t('survey:form_survey_edited'));
    } catch (error: any) {
      AlertService.error(t('survey:survey_save_error'));
      throw error;
    } finally {
      setLoading(false);
    }
  }, [orgId, survey, survey, t, setLoading]);

  return (
    <Column className={`QuestionsEditor${loading ? ' loading' : ''}`}>
      <div className="titleBar">
        <h2>{ t('survey:pulse_survey_questions_title') }</h2>
        <Button
          type="primary"
          size="large"
          isLoading={loading}
          onClick={onSubmit}
        >
          <Trans i18nKey="common:save" />
        </Button>
      </div>
      <p>
        <Trans
          i18nKey="survey:pulse_survey_questions_description"
          components={[
            <a
              rel="noreferrer"
              href="https://google.com"
              target="_blank"
            />
          ]}
        />
      </p>
      <QuestionsAccordion
        questions={questions}
        survey={survey}
        setSurvey={setSurvey}
      />
    </Column>
  );
});

export default QuestionsEditor;
