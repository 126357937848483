import React, {
  memo, useCallback, useEffect, useMemo, useState
} from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';

import ModalContent from '@common/components/modal/modal-content';
import ModalWindow from '@common/components/modal/modal-window';
import { Api } from '@common/services/api';
import Spinner from '@common/components/spinner';
import { Select } from '@common/components/form/inputs/select';
import { BarChart } from '@common/components/chart';
import * as orgSelectors from '@modules/organisation/selectors/organisation';
import { getDateQuery } from './utils';

import './category-compare-modal.scss';

type CategoryCompareContentProps = {
  category: string;
  onHide: () => void;
  surveyId: string;
  organisationId: string;
  group: string;
  predicates: any[];
};

const formatter = (value: number) => `${value.toFixed(1)}`;

const CategoryCompareContent = memo(({
  category, onHide, surveyId, organisationId, group, predicates
}: CategoryCompareContentProps) => {

  const [response, setResponse] = useState<any>(null);

  useEffect(() => {
    const url = `/v1/organisations/${organisationId}` +
      `/pulse_surveys/${surveyId}/analytics/compare/metric/${category}` +
      `?${getDateQuery(predicates)}&compare_type=${group}`;

    Api.get(url).then((res: any) => setResponse(res));
  }, [organisationId, surveyId, category, group, setResponse]);

  const { t } = useTranslation();

  const title = (
    <>
      <strong>{ t(`survey:pulse_survey_category_${category}`) }</strong>
      <span>
        {
          group === 'networks' ?
            t('survey:pulse_between_networks') :
            t('survey:pulse_between_functions')
        }
      </span>
    </>
  );

  const groupOptions = useMemo(() => {
    return [{
      value: 'networks',
      label: t('survey:pulse_compare_between_networks')
    }, {
      value: 'functions',
      label: t('survey:pulse_compare_between_functions')
    }];
  }, [t]);

  const history = useHistory();
  const { url } = useRouteMatch();
  const onGroupChange = useCallback((opt: any) => {
    setResponse(null);
    const newUrl = `${url}?category=${category}&group=${opt.value}`;
    history.push(newUrl);
  }, [history, url, category, setResponse]);

  const functions = useSelector(orgSelectors.functions);
  const networks = useSelector(orgSelectors.networks);

  const groups = group === 'networks' ? networks : functions;

  // console.log("debug functions", functions);
  // console.log("debug networks", networks);

  const metrics = response?.data?.metrics;

  const data = useMemo(() => {
    if (!Array.isArray(metrics)) return null;

    const idPropertyName = group === 'networks' ? 'network_id' : 'function_id';

    // @ts-expect-error
    return metrics.toSorted((a: any, b: any) => b.value - a.value).map((metric: any) => {
      const groupElement = groups.find(({ id }: any) => {
        return parseInt(id) === parseInt(metric[idPropertyName]);
      });
      if (!groupElement || typeof groupElement.name !== 'string') {
        return null;
      }
      return {
        ...metric,
        name: groupElement.name
      };
    });
  }, [metrics, group, groups]);

  const values = useMemo(() => {
    return [{
      label: t(`survey:pulse_survey_category_${category}`),
      dataKey: 'value',
      fill: '#FF9500',
      checked: true
    }];
  }, [t, category]);

  return (
    <ModalContent
      title={title}
      onHide={onHide}
      hideConfirm
      cancelButtonText={t('common:close')}
    >
      <div className="commands">
        <Select
          clearable={false}
          options={groupOptions}
          value={group}
          onChange={onGroupChange}
        />
        {/*
          TODO need to implement report button when we have the API endpoint
        */}
      </div>

      {
        Array.isArray(data) ?
          (
            <BarChart
              data={data}
              legend={false}
              XAxisDataKey="name"
              orderKey="value"
              formatter={formatter}
              values={values}
            />
          ) : (
            <Spinner size="large" centered />
          )
      }
    </ModalContent>
  );

});

type CategoryCompareModalProps = CategoryCompareContentProps & {
  show: boolean;
};

const CategoryCompareModal = memo(({
  category, show, onHide, surveyId, organisationId, group, predicates
}: CategoryCompareModalProps) => {
  return (
    <ModalWindow
      size="large"
      show={show}
      onHide={onHide}
      className="CategoryCompareModal"
    >
      <CategoryCompareContent
        surveyId={surveyId}
        organisationId={organisationId}
        onHide={onHide}
        category={category}
        group={group}
        predicates={predicates}
      />
    </ModalWindow>
  );
});

export default CategoryCompareModal;
