import React from 'react';
import moment from 'moment';

type TooltipProps = {
  active?: boolean;
  payload?: any[];
  tooltipValueLabel: string;
};

const Tooltip = (props: TooltipProps) => {
  const { active, payload, tooltipValueLabel } = props;

  const [data] = (payload || []);

  // console.log('debug Tooltip props', props);
  // console.log("debug Tooltip data", data);

  if (active && data) {

    const pastColor = payload!.find((e: any) => e.dataKey === 'pastValue')?.color;
    const currentColor = payload!.find((e: any) => e.dataKey === 'currentValue')?.color;

    const current = (data.payload.date && 'currentValue' in data.payload) ? {
      date: data.payload.date,
      value: data.payload.currentValue
    } : null;
    const past = (data.payload.pastDate && 'pastValue' in data.payload) ? {
      date: data.payload.pastDate,
      value: data.payload.pastValue
    } : null;

    return (
      <div className="Graph__Tooltip">
        {current && (
          <>
            <div className="Graph__Tooltip__Label">
              { moment(current.date).format('MMMM D, YYYY') }
            </div>
            <div className="Graph__Tooltip__Row">
              <div
                className="Graph__Tooltip__Row__Stroke"
                style={{ backgroundColor: currentColor }}
              />
              <div className="Graph__Tooltip__Row__Name">
                { tooltipValueLabel }
              </div>
              { current.value }
            </div>
          </>
        )}
        {past && (
          <>
            <div className="Graph__Tooltip__Label">
              { moment(past.date).format('MMMM D, YYYY') }
            </div>
            <div className="Graph__Tooltip__Row">
              <div
                className="Graph__Tooltip__Row__Stroke"
                style={{ backgroundColor: pastColor }}
              />
              <div className="Graph__Tooltip__Row__Name">
                { tooltipValueLabel }
              </div>
              { past.value }
            </div>
          </>
        )}
      </div>
    );
  }

  return null;
};

export default Tooltip;
