import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from '../questions-editor';
import CenteredCircleChart, { ChartBadge } from './centered-circle-chart';
import { AreaChart } from '@common/components/chart';
import {
  useAreaChartData,
  useAreaChartValues
} from './overall-engagement-score';
import { formatParticipationRate, getCategoryLimitDates } from './utils';
import Tooltip from './tooltip';
import { PulseSurveyResponse } from '@modules/survey/types/objects';

type ParticipationRateProps = {
  data: any[];
  survey: PulseSurveyResponse;
};

const ParticipationRate = memo(({ data, survey }: ParticipationRateProps) => {
  const { t } = useTranslation();

  const participation = useMemo(() => {
    // @ts-expect-error
    return data.metrics.find((metric: any) => metric.name === 'participation');
  }, [data]);

  // console.log("debug participation", participation);

  const areaChartValues = useAreaChartValues(
    participation.time_series.past,
    participation.time_series.current
  );
  const areaChartData = useAreaChartData(participation);

  const memberCount = survey?.data?.audience?.member_count || 0;
  const percentage = (participation.value / memberCount) * 100;

  const circleChartData = useMemo(() => {
    return [{
      name: 'value',
      value: percentage
    }, {
      name: 'empty-space',
      value: 100 - percentage
    }];
  }, [percentage]);

  const tooltipLabel = t('survey:pulse_survey_participation_percentage');

  const { startDate, endDate } = getCategoryLimitDates(participation);

  const [rateLabel, chartClass] = useMemo(() => {
    if (percentage <= 30) {
      return [t('survey:pulse_score_bad'), 'red'];
    }
    if (percentage <= 50) {
      return [t('survey:pulse_score_neutral'), 'gray'];
    }
    if (percentage <= 70) {
      return [t('survey:pulse_score_good'), ''];
    }
    return [t('survey:pulse_score_very_good'), 'green2'];
  }, [percentage, t]);

  return (
    <Column>
      <h2>
        { t('survey:pulse_survey_participation_rate_colum_title') }
      </h2>
      <div className="charts">
        <div className="circleChart">
          <CenteredCircleChart
            id="participationChart"
            data={circleChartData}
            className={chartClass}
            indicators={(
              <ChartBadge
                rateLabel={rateLabel}
                unit="%"
                scoreChange={participation.change}
                startDate={startDate}
                endDate={endDate}
              />
            )}
          >
            <h2>{ formatParticipationRate(percentage) }<span>%</span></h2>
            <p>
              { participation.value }
              {' '}
              {
                participation.value > 1 ?
                  t('common:submissions') :
                  t('common:submission')
              }
            </p>
          </CenteredCircleChart>
        </div>
        <div className="areaChart">
          <AreaChart
            legend
            data={areaChartData}
            values={areaChartValues}
            XAxisDataKey="date"
            tint="#5856D6"
            CustomTooltip={<Tooltip tooltipValueLabel={tooltipLabel} />}
          />
        </div>
      </div>
    </Column>
  );
});

export default ParticipationRate;
