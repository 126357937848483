import React, { memo, useState, useCallback } from 'react';
import Overview from '@common/components/overview';
import Container from '@common/components/container';
import { useTranslation } from 'react-i18next';
import { Button } from '@common/components/button';
import { useHistory } from 'react-router-dom';
import { Api } from '@common/services/api';
import { AlertService } from '@common/services/alert';
import { EPredicateFields, EPredicateOperators } from '@common/definitions';
import './pulse-surveys-overview.scss';

type PulseOverviewNoResultsProps = {
  organisation: any;
  pulseSurveysEnabled: boolean;
};

export const usePulseSurveyCreator = (organisation: any) => {
  const { t } = useTranslation();
  const [creating, setCreating] = useState(false);
  const history = useHistory();
  const dueDateOffset = organisation.settings?.onboarding_due_date_offset;
  const createSurvey = useCallback(async () => {
    try {
      setCreating(true);
      const path = `/v1/organisations/${organisation.id}/pulse_surveys`;

      const result = await Api.post(path, {
        name: t('survey:pulse_survey_default_name'),
        status: 'draft',
        settings: {
          frequency: '2w',
          questions_per_round: 3
        },
        questions: [],
        audience: {
          predicates: [{
            attribute: EPredicateFields.DAYS_IN_SERVICE,
            comparison: EPredicateOperators.GTE,
            value: typeof dueDateOffset === 'number' ? dueDateOffset : 30
          }],
          predicate_type: 'match_all'
        }
      });
      // @ts-expect-error
      const surveyId = result?.data?.id;
      if (typeof surveyId !== 'string') {
        throw new Error('missing survey id');
      }

      setCreating(false);
      history.push(`/admin/surveys/pulse/${surveyId}/edit/questions`);
    } catch (error: any) {
      AlertService.error(t('survey:form_survey_create_error'));
      setCreating(false);
      throw error;
    }
  }, [setCreating, organisation, t, history, dueDateOffset]);

  return { createSurvey, creating };
};

const PulseOverviewNoResults = memo(({
  organisation,
  pulseSurveysEnabled
}: PulseOverviewNoResultsProps) => {

  const { t } = useTranslation();
  const { createSurvey, creating } = usePulseSurveyCreator(organisation);

  const gotVideo = t('survey:pulse_intro_video').startsWith('http');

  const pulseLandingImage = (
    <img
      src={
        gotVideo ?
          '/static/images/survey/pulse_landing.jpg' :
          '/static/images/survey/pulse_landing_no_icon.jpg'
      }
      alt={t('survey:pulse_overview_small_title')}
    />
  );

  return (
    <Container.Content className="PulseOverviewNoResults">
      <Overview>
        <Overview.Content>
          <h3 className="smallTitle">
            { t('survey:pulse_overview_small_title') }
          </h3>
          <h1>{ t('survey:pulse_overview_card_title') }</h1>

          <div>
            <div className="section featurePreview">
              {
                gotVideo ? (
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={t('survey:pulse_intro_video')}
                  >
                    { pulseLandingImage }
                  </a>
                ) : (
                  pulseLandingImage
                )
              }
            </div>
            <div className="section description">
              <h3>{ t('survey:pulse_overview_card_description_1') }</h3>
              <h3>{ t('survey:pulse_overview_card_description_2') }</h3>

              <ul>
                <li>{ t('survey:pulse_overview_card_list_item_1') }</li>
                <li>{ t('survey:pulse_overview_card_list_item_2') }</li>
                <li>{ t('survey:pulse_overview_card_list_item_3') }</li>
                <li>{ t('survey:pulse_overview_card_list_item_4') }</li>
              </ul>
              {
                pulseSurveysEnabled ?
                  <Button
                    className="createSurveyBtn"
                    size="large"
                    type="primary"
                    isLoading={creating}
                    onClick={createSurvey}
                  >
                    { t('survey:pulse_overview_call_to_action') }
                  </Button> :
                  <Button
                    className="createSurveyBtn"
                    size="large"
                    type="primary"
                    onClick={() => window.Intercom('showNewMessage')}
                  >
                    { t('survey:pulse_survey_unsubscribed_call_to_action') }
                  </Button>
              }

              {
                gotVideo ? (
                  <a rel="noreferrer" target="_blank" href={t('survey:pulse_intro_video')}>
                    <Button size="large" type="inverted-primary">
                      { t('survey:pulse_overview_watch_video') }
                    </Button>
                  </a>
                ) :
                null
              }
            </div>
          </div>
        </Overview.Content>
      </Overview>
    </Container.Content>
  );
});

export default PulseOverviewNoResults;
