import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from '@common/components/button';

import { EPlanPackageConfig } from '@common/definitions';
import { useAppSelector } from '@common/hooks';
import { selected } from '@modules/organisation/selectors/organisation';
import getCurrentPlanPackage from '@common/utils/data/get-current-plan-package';
import {
  getTranslationKeyCTA, getTranslationKeyPlanName, getTranslationKeyTitle, getUpgradePlanPackage,
} from '@common/components/upgrade-plan-package-modal/utils';

import './styles.scss';

type Props = {
  packageId: `${EPlanPackageConfig}`;
};

const PlanPackageENPSOverlay = ({ packageId }: Props) => {
  const { t } = useTranslation();
  const organisation = useAppSelector(selected);
  const current = getCurrentPlanPackage(organisation);
  const upgrade = getUpgradePlanPackage(packageId, current?.level, organisation);
  return (
    <div className="PlanPackageENPSOverlay">
      <h2 className="PlanPackageENPSOverlay__Title">
        {t(getTranslationKeyTitle(packageId))}
      </h2>
      <p className="PlanPackageENPSOverlay__Text">
        {(upgrade && (
          <Trans
            i18nKey={getTranslationKeyCTA(packageId)}
            values={{
              nextPlanName: `<strong>${t(getTranslationKeyPlanName(upgrade.level))}</strong>`,
            }}
          />
        )) || t('pricing:not_allowed_no_upgrade_cta')}
      </p>
      <Button
        type="blue"
        size="large"
        onClick={() => window.Intercom?.('showNewMessage')}
      >
        {t('pricing:button_customer_success')}
      </Button>
      <Button
        size="large"
        onClick={() => window.open(t('pricing:link'), '_blank')}
      >
        {t('pricing:learn_more')}
      </Button>
    </div>
  );
};

export default PlanPackageENPSOverlay;
