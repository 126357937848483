import React, { memo } from 'react';
import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import { Route } from 'react-router';
import * as R from 'ramda';
import { memoize } from 'lodash';

import { TopNavigationBar } from '@common/components/navigation-bar/top-navigation-bar';
import * as AlertService from '@common/services/alert';
import Container from '@common/components/container';
import Placeholder from '@common/components/placeholder';
import { useQueryParam } from '@common/hooks/url';
import isFormValidById from '@common/utils/is-form-valid-by-id';
import { Button } from '@common/components/button';
import PhonePreview from '../../../learning/components/phone-preview';
import { pageWrapper, EEventNames } from '../../../../../client/analytics';
import { QuestionDropdown } from '../../components/question-dropdown';
import { QuestionsList } from '../../components/questions-list';
import QuestionContainer from '../question/question-container';
import SurveyForm from '../../forms/survey';
import * as surveySelector from '../../selectors/surveys';
import * as draftReducer from '../../../learning/reducers/draft';
import * as screensReducer from '../../../learning/reducers/screens';

import { EQuestionTypes } from '../../definitions';

const enabledComponents = [
  EQuestionTypes.CONTENT,
  EQuestionTypes.TEXT,
  EQuestionTypes.MULTIPLE_CHOICE,
  EQuestionTypes.SLIDER,
];

const isFormValid = memoize((form) => isFormValidById(form, 'question-'));

class EditSurveyContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialOrder: [],
    };

    this.handleAddQuestion = this.handleAddQuestion.bind(this);
    this.handleOpenQuestion = this.handleOpenQuestion.bind(this);
    this.handleDuplicateQuestion = this.handleDuplicateQuestion.bind(this);
    this.handleLeave = this.handleLeave.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handlePublish = this.handlePublish.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleSaveOrder = this.handleSaveOrder.bind(this);

    if (!props.match.params.screenId && props.survey && props.survey.screens[0]) {
      this.handleOpenQuestion(props.survey.id, props.survey.screens[0].id, true);

      this.state.initialOrder = props.survey.screens.map((screen) => screen.id);
    }
  }

  UNSAFE_componentWillMount() {
    const { match } = this.props;

    if (match.params.id !== 'create') {
      this.handleFetchSurvey(match.params.id);
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const { match } = this.props;
    if (match.params.id !== nextProps.match.params.id) this.handleFetchSurvey(nextProps.match.params.id);
  }

  componentWillUnmount() {
    this.props.resetDraft();

    // Unset listener
    if (this.unsetBeforeUnload) this.unsetBeforeUnload();
  }

  static props;

  unsetBeforeUnload;

  handleFetchSurvey(id) {
    const { match: { params }, fetchSurvey } = this.props;

    fetchSurvey(id)
      .then(({ item: survey }) => {
        if (params.screenId && R.find(R.propEq('id', params.screenId), survey.screens)) {
          this.handleOpenQuestion(survey.id, params.screenId, true);
        } else if (survey.screens[0]) {
          this.handleOpenQuestion(survey.id, survey.screens[0].id, true);
        }
      });
  }

  handleOpenQuestion(surveyId, id, replace = false) {
    const { history, location: { search } } = this.props;

    const url = `/admin/surveys/${surveyId}/edit/screens/${id}${search}`;

    return replace ? history.replace(url) : history.push(url);
  }

  async handleAddQuestion(question) {
    const { match, createQuestion } = this.props;

    const payload = question ? { components: [question] } : {};

    const { screen } = await createQuestion(match.params.id, payload);

    this.handleOpenQuestion(match.params.id, screen.id);
  }

  async handleDuplicateQuestion(item) {
    const { match, duplicateQuestion } = this.props;

    const { screen } = await duplicateQuestion(match.params.id, item);

    this.handleOpenQuestion(match.params.id, screen.id);
  }

  handleLeave() {
    const { survey, match: { params: { screenId } }, getState, t } = this.props;
    const state = getState();

    const draft = draftReducer.getScreen(state, screenId);
    const screen = { ...screensReducer.findById(state, screenId), published: undefined, points: undefined };

    if (!survey || !screen || R.equals(draft, screen)) return;

    return t('survey:test');
  }

  handleBack() {
    const { history, back } = this.props;
    history.push(back || '/admin/surveys');
  }

  async handleSave() {
    const { match: { params }, saveDraft, t } = this.props;

    try {
      await saveDraft(params.id);

      AlertService.success(t('survey:survey_form_alert_success'));
    } catch (response) {
      AlertService.forStatus(response.status_code, {
        '422': t('survey:survey_form_alert_check_content'),
        warning: t('survey:survey_form_alert_warning'),
        error: t('survey:survey_form_alert_error'),
      });

      throw response;
    }
  }

  async handlePublish() {
    const { history, match: { params } } = this.props;

    try {
      await this.handleSave();

      history.push(`/admin/surveys/${params.id}/publish`);
    } catch (err) {
      // No redirect
    }
  }

  handleDrag(screenId, index) {
    const { survey } = this.props;

    if (!survey) return;

    const order = R.pipe(R.sortBy(R.prop('index')), R.pluck('id'))(survey.screens);

    const newOrder = R.insert(
      index,
      screenId,
      R.remove(R.indexOf(screenId, order), 1, order),
    );

    this.props.updateScreenOrder(survey.id, newOrder);
  }

  async handleSaveOrder(order) {
    const { survey, saveScreenOrder, t } = this.props;

    try {
      await saveScreenOrder(survey.id, order);

      AlertService.success(t('survey:survey_order_alert_success'));
    } catch (response) {
      AlertService.forStatus(response.status_code, {
        warning: t('survey:survey_order_alert_warning'),
        error: t('survey:survey_order_alert_error'),
      });
    }
  }

  render() {
    const {
      form,
      history,
      match: { params },
      survey,
      deleteQuestion,
      t,
    } = this.props;
    const formIsValid = isFormValid(form);
    return (
      <Container name="Survey">
        <TopNavigationBar
          breadcrumbs={[
            { name: t('survey:breadcrumb_surveys'), path: '/admin/surveys' },
            survey && { name: survey.title },
          ]}
          form={SurveyForm}
          formProps={{
            survey,
            history,
          }}
          title={
            params.id === 'create'
              ? <span className="Survey__Title"><Trans i18nKey="survey:name_the_survey" /></span>
              : survey?.title
          }
          badge={survey?.status}
          action={(
            <>
              <Button onClick={this.handleBack}>
                <Trans i18nKey="survey:go_back" />
              </Button>
              <Button disabled={!formIsValid} type="inverted-primary" onClick={this.handleSave}>
                <Trans i18nKey="survey:save" />
              </Button>
              <Button disabled={!formIsValid} type="primary" onClick={this.handlePublish}>
                <Trans i18nKey="survey:select_audience" />
              </Button>
            </>
          )}
        />
        {params.id === 'create' && <SurveyForm form="create-survey" history={history} />}
        {((survey?.screens.length === 0) || params.id === 'create') ? (
          <Placeholder
            image="/static/images/modules-placeholder.svg"
            title={t('survey:survey_placeholder_title')}
            description={t('survey:survey_placeholder_description')}
            action={(
              <QuestionDropdown
                enabledComponents={enabledComponents}
                onSelect={this.handleAddQuestion}
              />
            )}
          />
        ) : survey && (
          <Container.Content horizontal>
            <div style={{ flex: '0 0 350px', minWidth: 0 }}>
              <QuestionsList
                screenId={params.screenId}
                screens={survey?.screens}
                addQuestion={this.handleAddQuestion}
                saveOrder={this.handleSaveOrder}
                deleteQuestion={(screenId) => deleteQuestion(survey.id, screenId)}
                openQuestion={(screenId) => this.handleOpenQuestion(survey.id, screenId)}
                duplicateQuestion={this.handleDuplicateQuestion}
                handleDrag={this.handleDrag}
                enabledComponents={enabledComponents}
                disabled={!formIsValid}
              />
            </div>
            <Route
              path="/admin/surveys/:surveyId/edit/screens/:id"
              render={(props) => (
                <>
                  <div style={{ flex: '1 1 auto', minWidth: 0 }}>
                    <QuestionContainer
                      {...props}
                      disabled={!formIsValid}
                      key={props.match.params.id}
                      enabledComponents={enabledComponents.filter((c) => c !== EQuestionTypes.CONTENT)}
                    />
                  </div>
                  <div style={{ flex: '0 0 334.75px', minWidth: 0 }}>
                    <PhonePreview
                      screenIDs={[props.match.params.id]}
                      navbarTitle={survey.title}
                      showBackButton={false}
                      focusedScreenId={props.match.params.id}
                    />
                  </div>
                </>
              )}
            />
          </Container.Content>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state, { match }) => ({
  survey: surveySelector.item(state, match.params.id),
  form: state.form,
});

const mapDispatchToProps = {
  fetchSurvey: require('../../actions').fetchSurvey,
  updateSurvey: require('../../actions').updateSurvey,
  createQuestion: require('../../actions').createQuestion,
  deleteQuestion: require('../../actions').deleteQuestion,
  duplicateQuestion: require('../../actions').duplicateQuestion,
  updateScreenOrder: require('../../actions').updateScreenOrder,
  saveScreenOrder: require('../../actions').saveScreenOrder,
  saveDraft: require('../../actions').saveDraft,
  resetDraft: require('../../../learning/actions/reset-draft').default,
  getState: () => (dispatch, getState) => getState(),
};

const EditSurvey = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withTranslation()(
    pageWrapper(EEventNames.VISITED_SURVEY_EDIT_PAGE)(EditSurveyContainer)
  )
);

const EditSurveyFunctionalWrapper = memo((props) => {
  const back = useQueryParam('back');
  return <EditSurvey {...props} back={back} />;
});

export default EditSurveyFunctionalWrapper;
