import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router';
import { TFunction, Trans, withTranslation } from 'react-i18next';

import { TopNavigationBar } from '@common/components/navigation-bar/top-navigation-bar';
import Container from '@common/components/container';
import { Button } from '@common/components/button';
import Spinner from '@common/components/spinner';
import SurveyDetailContainer from '../survey-detail';
import ResponsesContainer from '../responses';
import SummaryContainer from '../summary';

import { useQueryParam } from '@common/hooks/url';
import { History } from '@common/types/objects';
import { StoreState } from '@common/types/store';
import { Survey } from '@modules/survey/types/objects';
import * as surveySelector from '../../selectors/surveys';
import * as surveyActions from '../../actions';

type Match = {
  params: { id: string };
};

type Props = {
  survey?: Survey;
  fetchSurvey: (nextCursor: string) => Promise<Request>;
  history: History;
  match: Match;
  t: TFunction;
};

const SurveyContainer: FC<Props> = ({
  history, match, survey, fetchSurvey, t,
}: Props) => {
  React.useEffect(() => {
    if (match.params.id !== 'create') fetchSurvey(match.params.id);
  }, []);

  const back = useQueryParam('back');

  return (
    <Container name="Survey">
      <TopNavigationBar
        breadcrumbs={[
          { name: t('survey:breadcrumb_surveys'), path: '/admin/surveys' },
          survey && { name: survey.title! },
        ]}
        title={survey?.title}
        badge={survey?.status}
        tabs={[{
          name: t('survey:survey_tabs_summary'),
          to: `/admin/surveys/${match.params.id}/summary`,
        }, {
          name: t('survey:survey_tabs_responses'),
          count: survey?.responded_count,
          to: `/admin/surveys/${match.params.id}/responses`,
        }, {
          name: t('survey:survey_tabs_settings'),
          to: `/admin/surveys/${match.params.id}/settings`,
        }]}
        action={(
          <Button onClick={() => history.push(back || '/admin/surveys')}>
            <Trans i18nKey="survey:go_back" />
          </Button>
        )}
      />
      {!survey ? (
        <Spinner centered size="large" />
      ) : (
        <Container.Content>
          <Switch>
            <Route path="/admin/surveys/:id/settings" component={SurveyDetailContainer} />
            <Route path="/admin/surveys/:id/responses/:responseId" component={ResponsesContainer} />
            <Route path="/admin/surveys/:id/responses" component={ResponsesContainer} />
            <Route path="/admin/surveys/:id/summary" component={SummaryContainer} />
          </Switch>
        </Container.Content>
      )}
    </Container>
  );
};

const mapStateToProps = (state: StoreState, { match }: Props) => ({
  survey: surveySelector.item(state, match.params.id),
});

const mapDispatchToProps = {
  fetchSurvey: surveyActions.fetchSurvey,
};

// @ts-expect-error
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SurveyContainer));
