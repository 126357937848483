import React, { memo, useMemo, useState, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import { Container } from '@common/components/container';
import { Overview } from '@common/components/overview';
import { AsyncTable, TableDropdown } from '@common/components/table';
import Placeholder from '@common/components/placeholder/placeholder';
import PulseOverviewNoResults, { usePulseSurveyCreator } from './pulse-overview-no-results';
import { ConfirmButton } from '@common/components/confirm-button';
import Badge from '@common/components/badge';
import EnpsChart from './enps-chart';
import { AlertService } from '@common/services/alert';
import { Button } from '@common/components/button';
import { TopNavigationBar } from '@common/components/navigation-bar';

import { Api } from '@common/services/api';
import { useComponentEnabledChecker } from '@common/hooks';
import { useIsAvailableInPlanPackage } from '@common/hooks/use-is-available-in-plan-package';
import { formatParticipationRate } from './analytics/utils';

import { EPulseSurveyResultTypes } from '@modules/survey/definitions';
import { EComponentTypes, EPlanPackageConfig, EStatus } from '@common/definitions';
import { EPulseSurveyStatus, PulseSurvey } from '@modules/survey/types/objects';

const surveyUrl = (survey: PulseSurvey) => {
  if (
    survey.status === EStatus.LIVE ||
    (
      survey.status === EStatus.ARCHIVED &&
      survey.analytics?.length > 0
    )
  ) {
    return `/admin/surveys/pulse/${survey.id}/edit/analytics`;
  }
  return surveyEditUrl(survey.id);
};

const surveyEditUrl = (surveyId: string) => {
  return `/admin/surveys/pulse/${surveyId}/edit/questions`;
};

const EnpsValue = memo(({ item }: any) => {
  const { t } = useTranslation();

  const enps = useMemo(() => {
    return item.analytics?.find((elem: any) => {
      return elem.name === EPulseSurveyResultTypes.ENPS;
    });
  }, [item]);

  if (enps?.distribution && typeof enps?.value === 'number') {
    const { detractors, promoters, passives, skipped } = enps.distribution;
    if ((detractors + promoters + passives + skipped) > 0) {
      return (
        <div className="EnpsValue">
          <span>{ enps.value }</span>
          <EnpsChart
            detractors={detractors.count}
            promoters={promoters.count}
            passives={passives.count}
            skipped={skipped.count}
          />
        </div>
      );
    }
  }

  return <small>{ t('survey:pulse_survey_no_data_yet') }</small>;
});

const PulseSurveysOverview = memo(({ organisation }: any) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [surveys, setSurveys] = useState<any>(null);

  const items = surveys || [];
  const noResult = !!(surveys && items.length <= 0);

  const pulseSurveysEnabled = useComponentEnabledChecker(EComponentTypes.PULSE_SURVEYS);
  const { createSurvey, creating } = usePulseSurveyCreator(organisation);
  // Attention: Pulse Surveys are paginated and no total count is returned.
  // If the pulse_surveys value in the plan package becomes higher than the limit
  // set in the fetch call, this check will not work anymore
  // See https://linear.app/oneteam/issue/ONE-1181/disable-pulse-survey-creation-based-on-feature-tiering#comment-1636461a
  const {
    isAvailable: canCreatePulseSurvey, showUpgradeModal,
  } = useIsAvailableInPlanPackage(EPlanPackageConfig.PULSE_SURVEYS, items.length);

  const columns = useMemo(() => {
    return [
      {
        label: t('survey:surveys_columns_status'),
        className: 'StatusCell'
      },
      {
        label: t('survey:surveys_columns_name'),
        className: 'Table__Cell__Title'
      },
      {
        label: t('survey:pulse_survey_enps_colum_title'),
        className: 'EnpsCell'
      },
      {
        label: t('survey:pulse_survey_participation_rate_colum_title'),
        className: 'ParticipationRateCell'
      }
    ];
  }, [t]);

  const fetchSurveys = useCallback(async (nextCursor: string | null) => {
    const query = Api.utils.toQuery({
      limit: 10,
      cursor: nextCursor || true
    });
    const url = `/v1/organisations/${organisation.id}/pulse_surveys?${query}`;
    const response: any = await Api.get(url);

    setSurveys((prevSurveys: any[] | null) => {
      return [
        ...(prevSurveys || []),
        ...(response?.data || [])
      ];
    });

    return response;
  }, [organisation, setSurveys]);

  const onDeleteClick = useCallback(async (surveyId: string) => {
    try {
      const url = `/v1/organisations/${organisation.id}/pulse_surveys/${surveyId}`;
      await Api.delete(url);
      setSurveys((prevSurveys: any) => {
        return (prevSurveys || []).filter((surv: any) => {
          return (`${surv.id}`) !== (`${surveyId}`);
        });
      });
    } catch (error: any) {
      AlertService.error(t('survey:survey_delete_error'));
      throw error;
    }
  }, [organisation, setSurveys]);

  const onDuplicateClick = useCallback(async (surveyId: string) => {
    try {
      const result = await Api.post(
        `/v1/organisations/${organisation.id}/pulse_surveys/${surveyId}/duplicate`
      );
      const duplicated = result.data;
      setSurveys((survs: any) => {
        return [
          duplicated,
          ...(survs || []),
        ];
      });
      AlertService.success(t('survey:form_survey_duplicated'));
    } catch (error: any) {
      AlertService.error(t('survey:form_survey_duplicate_warning'));
      throw error;
    }
  }, [organisation, setSurveys, t]);

  const changeSurveyStatus = useCallback(async (
    surveyId: string,
    status: EStatus | EPulseSurveyStatus
  ) => {
    const url = `/v1/organisations/${organisation.id}/pulse_surveys/${surveyId}`;
    try {
      await Api.patch(url, { status });
      AlertService.success(t('survey:form_survey_edited'));
      setSurveys((prevSurveys: any[] | null) => {
        return (prevSurveys || []).map((survey: any) => {
          if (survey.id === surveyId) {
            return { ...survey, status };
          }
          return survey;
        });
      });
    } catch (error: any) {
      AlertService.error(t('survey:survey_save_error'));
      throw error;
    }
  }, [organisation, setSurveys]);

  const onPause = useCallback((surveyId: string) => {
    return changeSurveyStatus(surveyId, EPulseSurveyStatus.PAUSED);
  }, [changeSurveyStatus]);

  const onArchive = useCallback((surveyId: string) => {
    return changeSurveyStatus(surveyId, EStatus.ARCHIVED);
  }, [changeSurveyStatus]);

  if (noResult || !pulseSurveysEnabled) {
    return (
      <PulseOverviewNoResults
        organisation={organisation}
        pulseSurveysEnabled={pulseSurveysEnabled}
      />
    );
  }

  return (
    <>
      <TopNavigationBar
        title={[
          t('survey:pulse_sidebar_overview_section')
        ]}
        action={(
          <Button
            type="primary"
            size="large"
            isLoading={creating}
            onClick={canCreatePulseSurvey ? createSurvey : showUpgradeModal}
            iconRight="add"
          >
            { t('survey:create_survey') }
          </Button>
        )}
      />
      <Container.Content className="PulseSurveysOverview">
        <Overview>
          <Overview.Content>
            {/* @ts-expect-error */}
            <AsyncTable
              columns={columns}
              data={{
                useCursor: true,
                onFetch: fetchSurveys
              }}
              items={items}
              renderRow={({ item }: any) => {
                const participation = item?.analytics?.find((elem: any) => {
                  return elem.name === EPulseSurveyResultTypes.PARTICIPATION;
                })?.value;

                const memberCount = item?.audience?.[0]?.member_count || 0;
                const percentage = (participation / memberCount) * 100;

                return [
                  <Badge status={item.status} />,
                  <Link to={surveyUrl(item)}>
                    {
                      item.name ||
                      <small><Trans i18nKey="survey:survey_item_no_title" /></small>
                    }
                  </Link>,
                  <EnpsValue item={item} />,
                  (
                    typeof percentage === 'number' &&
                    percentage === percentage ?
                      <span>{ `${formatParticipationRate(percentage)}%`}</span> :
                      <small>{ t('survey:pulse_survey_no_data_yet') }</small>
                  )
                ];
              }}
              placeholder={(
                <Placeholder
                  title={
                    t('survey:no_surveys', {
                      context: undefined,
                      status: undefined
                    })
                  }
                />
              )}
              ActionComponent={({ item }) => (
                <TableDropdown id={`channel-${item.id}`}>
                  <TableDropdown.Item onClick={() => history.push(surveyEditUrl(item.id))}>
                    <Trans i18nKey="survey:survey_item_actions_edit" />
                  </TableDropdown.Item>
                  <TableDropdown.Item
                    onClick={() => {
                      if (canCreatePulseSurvey) {
                        onDuplicateClick(item.id);
                      } else {
                        showUpgradeModal();
                      }
                    }}
                  >
                    <Trans i18nKey="survey:survey_item_actions_duplicate" />
                  </TableDropdown.Item>
                  {
                    item.status !== EPulseSurveyStatus.PAUSED && (
                      <TableDropdown.Item onClick={() => onPause(item.id)}>
                        { t('survey:pulse_pause') }
                      </TableDropdown.Item>
                    )
                  }
                  {
                    item.status !== EStatus.ARCHIVED && (
                      <TableDropdown.Item onClick={() => onArchive(item.id)}>
                        { t('survey:survey_item_actions_archive') }
                      </TableDropdown.Item>
                    )
                  }
                  <ConfirmButton
                    title={t('survey:survey_item_actions_delete_confirm')}
                    onConfirm={() => onDeleteClick(item.id)}
                  >
                    <TableDropdown.Item danger>
                      <Trans i18nKey="survey:survey_item_actions_delete" />
                    </TableDropdown.Item>
                  </ConfirmButton>
                </TableDropdown>
              )}
            />
          </Overview.Content>
        </Overview>
      </Container.Content>
    </>
  );
});

export default PulseSurveysOverview;
