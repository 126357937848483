import React, { memo } from 'react';
import { Link, Route } from 'react-router-dom';
import { Icon } from '../icon';
import './breadcrumbs.scss';

type CrumbData = {
  label: string;
  href: string;
};

type CrumbProps = CrumbData & {
  showChevron: boolean;
};

const Crumb = memo(({ showChevron, label, href }: CrumbProps) => {
  return (
    <Route path={href}>
      { showChevron && <Icon type="chevron_right" /> }
      <Link to={href}>{ label }</Link>
    </Route>
  );
});

type BreadcrumbsProps = {
  crumbs: CrumbData[];
};

const Breadcrumbs = memo(({ crumbs }: BreadcrumbsProps) => {
  // console.log("debug Breadcrumbs crumbs", crumbs);
  return (
    <div className="Breadcrumbs">
      {
        crumbs.map((props, index) => {
          return (
            <Crumb {...props} showChevron={index > 0} />
          );
        })
      }
    </div>
  );
});

export default Breadcrumbs;
