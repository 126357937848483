import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Pie, PieChart, ResponsiveContainer } from 'recharts';
import { Link, useRouteMatch } from 'react-router-dom';

import ProportionChart from '@common/components/proportion-chart/proportion-chart';
import { Column } from '../questions-editor';
import { ChartBadge } from './centered-circle-chart';
import { Button } from '@common/components/button';

import { detractorsColor, naOrSkippedColor, passivesColor, promotersColor } from '../enps-chart';
import { getCategoryLimitDates } from './utils';
import { useIsAvailableInPlanPackage } from '@common/hooks/use-is-available-in-plan-package';

import { EPulseSurveyResultTypes } from '@modules/survey/definitions';
import { ENPSPulseSurveyMetric } from '../../../types/objects';
import { EPlanPackageConfig } from '@common/definitions';
import PlanPackageENPSOverlay from '../../plan-package-enps-overlay';
import PlanPackageOverlay from '@common/components/plan-package-overlay/plan-package-overlay';

type EnpsDistributionLegendRowProps = {
  percentage: number;
  label: string;
  color: string;
};

const EnpsDistributionLegendRow = memo(({
  percentage, label, color
}: EnpsDistributionLegendRowProps) => {
  return (
    <div className="EnpsDistributionLegendRow">
      <div className="color" style={{ backgroundColor: color }} />
      <h3>{percentage}%</h3>
      <p>{label}</p>
    </div>
  );
});

// TODO refactor: not optimal to use a hook for different cases of
// PromotersCircleChart usage, best to create a component that
// handles all the different cases
export const usePromotersCircleChartData = (score: number) => {
  const { t } = useTranslation();
  return useMemo(() => {
    if (score <= -60) {
      return [t('survey:pulse_score_very_bad'), 'red'];
    }
    if (score <= -20) {
      return [t('survey:pulse_score_bad'), 'orange'];
    }
    if (score <= 10) {
      return [t('survey:pulse_score_neutral'), 'gray'];
    }
    if (score <= 50) {
      return [t('survey:pulse_score_good'), ''];
    }
    if (score <= 80) {
      return [t('survey:pulse_score_very_good'), 'green2'];
    }
    return [t('survey:pulse_score_excellent'), 'green2'];
  }, [score, t]);
};

type PromotersCircleChartProps = {
  score: number;
  className?: string;
};

export const PromotersCircleChart = memo(({
  score, className
}: PromotersCircleChartProps) => {

  const data = useMemo(() => {
    const value = 100 + score;
    return [{
      name: 'score',
      value
    }, {
      name: 'empty-space',
      value: 200 - value
    }];
  }, [score]);

  return (
    <div className="PromotersCircleChart">
      <p className="zero">0</p>
      <ResponsiveContainer
        className={`CircleChart reverse${className ? ` ${className}` : ''}`}
        width={160}
        height={160}
      >
        <PieChart width={160} height={160}>
          <Pie
            data={data}
            startAngle={-60}
            endAngle={240}
            cx="50%"
            cy="50%"
            innerRadius={75}
            outerRadius={80}
            dataKey="value"
          />
        </PieChart>
      </ResponsiveContainer>
      <p className="minus100">-100</p>
      <p className="plus100">100</p>
      <h2 className="score">{Math.round(score)}</h2>
    </div>
  );
});


type PromotersInYourOrganizationProps = {
  data: {
    metrics: ENPSPulseSurveyMetric[];
  };
};

const EXAMPLE_DATA = {
  value: 45,
  change: 18,
  distribution: {
    promoters: { percentage: 46 },
    passives: { percentage: 36 },
    detractors: { percentage: 0 },
    skipped: { percentage: 18 },
  },
};

const PromotersInYourOrganization = memo(({ data }: PromotersInYourOrganizationProps) => {
  const { t } = useTranslation();
  const match = useRouteMatch();

  const {
    isAvailable: canViewENPSTracking,
  } = useIsAvailableInPlanPackage(EPlanPackageConfig.PULSE_SURVEYS_ENPS_TRACKING);

  const eNPS = useMemo(() => {
    const res = data.metrics.find((metric: any) => metric.name === 'eNPS')!;
    if (canViewENPSTracking) {
      return res;
    }
    return {
      ...res,
      ...EXAMPLE_DATA,
    };
  }, [data, canViewENPSTracking]);

  const { startDate, endDate } = getCategoryLimitDates(eNPS);
  const [rateLabel, chartClass] = usePromotersCircleChartData(eNPS.value);

  const bars = useMemo(() => {
    return [{
      color: promotersColor,
      value: eNPS.distribution.promoters.percentage
    }, {
      color: passivesColor,
      value: eNPS.distribution.passives.percentage
    }, {
      color: detractorsColor,
      value: eNPS.distribution.detractors.percentage
    }, {
      color: naOrSkippedColor,
      value: eNPS.distribution.skipped.percentage
    }];
  }, [eNPS]);

  return (
    <Column className="PromotersInYourOrganization">
      <PlanPackageOverlay
        className="PromotersInYourOrganization__Content"
        opacity="light"
        blur="heavy"
        disabled={!canViewENPSTracking}
        overlayContent={<PlanPackageENPSOverlay packageId={EPlanPackageConfig.PULSE_SURVEYS_ENPS_TRACKING} />}
      >
        <h2>{ t('survey:pulse_analytics_enps_title') }</h2>
        <small>{ t('survey:pulse_analytics_enps_description') }</small>
        <div className="enpsScore">
          <h2>{ t('survey:pulse_analytics_enps_score') }</h2>
          <div>
            <PromotersCircleChart className={chartClass} score={eNPS.value} />
            <div className="indicators">
              <ChartBadge
                rateLabel={rateLabel}
                scoreChange={eNPS.change}
                startDate={startDate}
                endDate={endDate}
              />
            </div>
          </div>
        </div>
        <div className="enpsDistribution">
          <h2>{ t('survey:pulse_analytics_enps_distribution') }</h2>
          <ProportionChart bars={bars} vertical height={170} />
          <div>
            <EnpsDistributionLegendRow
              label={t('survey:pulse_analytics_enps_promoters')}
              percentage={bars[0].value}
              color={promotersColor}
            />
            <EnpsDistributionLegendRow
              label={t('survey:pulse_analytics_enps_passives')}
              percentage={bars[1].value}
              color={passivesColor}
            />
            <EnpsDistributionLegendRow
              label={t('survey:pulse_analytics_enps_detractors')}
              percentage={bars[2].value}
              color={detractorsColor}
            />
            <EnpsDistributionLegendRow
              label={t('survey:pulse_analytics_enps_na_or_skipped')}
              percentage={bars[3].value}
              color={naOrSkippedColor}
            />
          </div>
        </div>
        {canViewENPSTracking && (
          <div className="bottomBar">
            <Link to={`${match.url}/categories?name=${EPulseSurveyResultTypes.ENPS}`}>
              <Button iconRight="chevron_right" type="blue" size="large">
                { t('survey:pulse_survey_view_enps_history') }
              </Button>
            </Link>
          </div>
        )}
      </PlanPackageOverlay>
    </Column>
  );
});

export default PromotersInYourOrganization;
