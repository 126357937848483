import React, { memo } from 'react';
import { QuestionIcon } from '../../components/question-icon';
import QuestionDataComponent from '../../components/question-data';
import { EQuestionTypes } from '@modules/survey/definitions';
import { Trans, useTranslation } from 'react-i18next';
import { AnswerStatistic } from '@modules/survey/types/objects';
import { Button } from '@common/components/button';

const allowedCompareTypes = [
  EQuestionTypes.MULTIPLE_CHOICE,
  EQuestionTypes.SLIDER,
];

type ReportQuestionProps = {
  number: number;
  question: any;
  report: any;
  responseCount: number;
  answerStatistics: AnswerStatistic[];
  fetchAnswers: (...args: any[]) => any;
  networkIds: string[];
  functionIds: string[];
  handleCompareQuestion: (id: string) => void;
};

const ReportQuestion = memo(({
  number,
  question,
  report,
  responseCount,
  answerStatistics,
  fetchAnswers,
  networkIds,
  functionIds,
  handleCompareQuestion
}: ReportQuestionProps) => {

  const { t } = useTranslation();

  return (
    <div className="Report__Question">
      <QuestionIcon type={question.type} />
      <div>
        <h2>{ number }.{' '}{ question.text }</h2>
        <span>
          <Trans
            i18nKey="survey:question_report_percentage"
            values={{
              total_count: report.responded_count,
              answered_count: responseCount,
              percentage: Math.round((responseCount / report.responded_count) * 100 || 0),
            }}
            components={[<b />]}
          />
          {question.type === EQuestionTypes.MULTIPLE_CHOICE && question.settings.allow_multiple_options && (
            <>
              {' '}
              &bull;
              {t('survey:question_multiple_choice')}
            </>
          )}
        </span>
        <QuestionDataComponent
          surveyId={report.survey_id}
          question={question}
          answerStatistics={answerStatistics}
          responseCount={responseCount}
          fetchAnswers={fetchAnswers}
          filter={{
            networkIds,
            functionIds,
          }}
        />
      </div>
      {allowedCompareTypes.includes(question.type) && (
        <Button onClick={() => handleCompareQuestion(question.id)} icon="sync_alt">
          <Trans i18nKey="survey:compare" />
        </Button>
      )}
    </div>
  );
});

export default ReportQuestion;
