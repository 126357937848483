import * as React from 'react';
import { reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';

import * as AlertService from '../../../../common/services/alert';
import Modal from '../../../../common/components/modal';
import { Button } from '../../../../common/components/button';
import { createSurvey, updateSurvey } from '../../actions';
import * as Form from '../../../../common/components/form';

const { Row, Group, Label, TextInput } = Form;

class SurveyForm extends React.Component {
  constructor() {
    super();

    this.setReference = (ref) => { this.modal = ref; };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { survey } = this.props;

    if (survey) this.handleInitialize(survey);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.survey && nextProps.survey) this.handleInitialize(nextProps.survey);
  }

  static props;
  modal;

  handleInitialize = (survey) => {
    this.props.initialize({ title: survey.title });
  };

  async handleSubmit(values, dispatch) {
    const { history, survey, t } = this.props;

    try {
      const payload = {
        title: values.title,
      };

      if (!survey) {
        const { item } = await dispatch(createSurvey(payload));

        AlertService.success(t('survey:form_survey_created'));
        history.replace(`/admin/surveys/${item.id}/edit${window.location.search}`);
      } else {
        await dispatch(updateSurvey(survey.id, payload));

        AlertService.success(t('survey:form_survey_edited'));
        if (this.modal) this.modal.handleClose();
      }
    } catch (response) {
      return AlertService.forStatus(response.status_code, {
        warning: t('learning:form_course_warning_saving_course'),
        error: t('learning:form_course_error_saving_course'),
      });
    }
  }

  render() {
    const {
      children, survey, invalid, pristine, submitting, handleSubmit, history, t,
    } = this.props;

    return (
      <Modal
        list
        ref={this.setReference}
        disableRootClose={!survey}
        onClose={!survey && (() => history.goBack())}
        size="small"
        title={survey ? t('survey:form_modal_update_survey') : t('survey:form_modal_create_survey')}
        wrapper={Modal.FormWrapper}
        wrapperProps={{
          onSubmit: handleSubmit(this.handleSubmit),
        }}
        content={(
          <Row>
            <Group>
              <Label forInput="title" text={t('survey:form_survey_title_label')} maxLength={30} />
              <TextInput name="title" placeholder={t('survey:form_survey_title_placeholder')} maxLength={30} />
            </Group>
          </Row>
        )}
        footer={(
          <Button
            type="primary"
            buttonType="submit"
            disabled={invalid || pristine}
            isLoading={submitting}
          >
            {survey ? t('survey:form_survey_update') : t('survey:form_survey_create')}
          </Button>
        )}
      >
        {children}
      </Modal>
    );
  }
}

export default withTranslation()(reduxForm({
  form: 'survey',
})(SurveyForm));
