/* eslint-disable */
import { AnswerStatistic } from "@modules/survey/types/objects";
import React, { memo, useMemo } from "react";
import { Trans } from "react-i18next";

type QuestionDataBarsProps = {
  answerStatistics: AnswerStatistic[];
};

const QuestionDataBars = memo(({ 
  answerStatistics, 
}: QuestionDataBarsProps) => {

  const total = useMemo(() => {
    return answerStatistics.reduce((acc, value) => {
      const { step, answer_count } = value;
      return acc + (answer_count * step);
    }, 0);
  }, [answerStatistics]);
  
  // We can't rely on the response count from the API, because in some cases we cap this number.
  // This happens, for example, when a total of 97 employees filled in the survey, but the organization (probably) has 80 users at the moment.
  // The API is capping this number to avoid displaying 97/80.
  // See https://linear.app/oneteam/issue/ONE-1646/investigate-response-count-and-assigned-count-for-surveys-in-report
  const count = useMemo(() => {
    return answerStatistics.reduce((acc, value) => {
      const { answer_count } = value;
      return acc + answer_count;
    }, 0);
  }, [answerStatistics]);

  return (
    <div className="Report__Question__Data">
      <div className="Report__Question__Data__Average">
        <Trans 
          i18nKey="survey:question_report_average" 
          values={{ 
            average: Math.round(total / count * 10) / 10 || 0 
          }} 
        />
      </div>
      <div className="Report__Question__Data__Bars">
        {
          answerStatistics.map(({ step, answer_count }: AnswerStatistic) => (
            <div key={step} className="Report__Question__Data__Bars__Row">
              { step }
              <div className="Report__Question__Data__Bars__Row__Bar">
                <div 
                  className="Report__Question__Data__Bars__Row__Bar__Fill" 
                  style={{ 
                    flex: `0 0 ${(answer_count / count) * 100 || 0}%` 
                  }}
                />
              </div>
              <div className="Report__Question__Data__Bars__Row__Count">
                { answer_count }
              </div>
              <div>
                { Math.round((answer_count / count) * 100 || 0) }
                %
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
});

export default QuestionDataBars;
