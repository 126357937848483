import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as moment from 'moment';
import Placeholder from '../../../../common/components/placeholder';
import Modal from '../../../../common/components/modal';
import AsyncList from '../../../../common/components/list/async';
import { Button } from '../../../../common/components/button';
import { EQuestionTypes } from '../../definitions';
import QuestionDataBars from './question-data-bars';

const AnswerComponent = ({ item }) => {
  const createdAt = moment(item.created_at);

  return (
    <div className="Report__Question__Data__Answer">
      <div>{item.text}</div>
      <span>{item.user ? item.user.full_name : <Trans i18nKey="survey:survey_response_anonymous" />}</span>
      <span>{createdAt.format('MMM D, YYYY')}</span>
      <span>{createdAt.format('HH:mm')}</span>
    </div>
  );
};

const QuestionDataComponent = ({
  surveyId,
  question,
  answerStatistics,
  responseCount,
  fetchAnswers,
  filter,
  showAll = false,
}) => {
  const { t } = useTranslation();

  switch (question.type) {
    case EQuestionTypes.MULTIPLE_CHOICE:
      return (
        <div className="Report__Question__Data">
          {answerStatistics.map(({ option, answer_count: answerCount }) => (
            <div key={option.id} className="Report__Question__Data__Option">
              <div className="Report__Question__Data__Option__Title">
                <h3>{option.value}</h3>
                <span className="employeesCount">
                  <Trans i18nKey="survey:question_report_option_employees" values={{ count: answerCount }} />
                </span>
                <span>
                  {Math.round(answerCount / responseCount * 100 || 0)}
                  %
                </span>
              </div>
              <div className="Report__Question__Data__Option__Bar">
                <div
                  className="Report__Question__Data__Option__Bar__Fill"
                  style={{ width: `${answerCount / responseCount * 100 || 0}%` }}
                />
              </div>
            </div>
          ))}
        </div>
      );
    case EQuestionTypes.SLIDER: {
      return (
        <QuestionDataBars
          answerStatistics={answerStatistics}
        />
      );
    }
    case EQuestionTypes.TEXT:
      const asyncList = (
        <AsyncList
          containerClassName="Report__Question__Data__Answers"
          data={{
            useCursor: true,
            onFetch: fetchAnswers,
            limit: showAll && responseCount,
            filter: {
              surveyId,
              questionId: question.id,
              ...filter,
            },
          }}
          renderRow={AnswerComponent}
        />
      );
      return (
        <div className="Report__Question__Data__Replies">
          <div className="Report__Question__Data">
            {answerStatistics.length === 0 && (
              <Placeholder title={t('survey:question_report_no_answers')} />
            )}
            {!showAll && answerStatistics.map((answer) => <AnswerComponent key={answer.id} item={answer} />)}
            {!showAll && answerStatistics.length > 0 && (
              <Modal
                content={asyncList}
              >
                <Button><Trans i18nKey="survey:question_report_show_all_answers" /></Button>
              </Modal>
            )}
            {showAll && asyncList}
          </div>
        </div>
      );
    default:
      return null;
  }
};

export default QuestionDataComponent;
