import React, { MouseEvent, memo, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';

import { SimpleScoreCircleChart } from './centered-circle-chart';
import { Icon } from '@common/components/icon';
import { Column } from '../questions-editor';
import { AreaChart } from '@common/components/chart';
import { Button } from '@common/components/button';
import Tooltip from './tooltip';

import { formatDate, getCategoryLimitDates, getCategorySeriesLimitDates } from './utils';
import { useIsAvailableInPlanPackage } from '@common/hooks/use-is-available-in-plan-package';
import { EPlanPackageConfig } from '@common/definitions';
import { EPulseSurveyCategories, EPulseSurveyResultTypes } from '@modules/survey/definitions';


type MetricBadgeProps = {
  name: string;
  value: number;
  change: number;
  label: string;
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
};

function toFixed(num: number, fixed: number) {
  const re = new RegExp(`^-?\\d+(?:.\\d{0,${fixed || -1}})?`);
  return parseFloat(num.toString().match(re)![0]).toFixed(1);
}

const MetricBadge = memo(({
  value, change, label, name, onClick,
}: MetricBadgeProps) => {
  const { url } = useRouteMatch();
  return (
    <Link
      to={`${url}/categories?name=${name}`}
      className="MetricBadge"
      onClick={onClick}
    >
      <Icon type="arrow_up" size={23} className="badgeIcon" />
      <div>
        <div>
          <h3>{ toFixed(value, 1) }</h3>
          {
            typeof change === 'number' && change !== 0 && (
              <>
                <Icon
                  type={change > 0 ? 'arrow_up' : 'arrow_down'}
                  className={change > 0 ? 'arrow_up' : 'arrow_down'}
                />
                <p>{change}</p>
              </>
            )
          }
        </div>
        <small>{ label }</small>
      </div>
    </Link>
  );
});

type BadgesProps = {
  className?: string;
  title: string;
  badges: Array<MetricBadgeProps & { key: number }>;
};

const Badges = memo(({
  className, title, badges, ...other
}: BadgesProps) => {
  return (
    <div className={`Badges${className ? ` ${className}` : ''}`}>
      <h2>{ title }</h2>
      <div className="badges">
        {
          badges.map((props: MetricBadgeProps & { key: number }) => {
            return <MetricBadge {...props} {...other} />;
          })
        }
      </div>
    </div>
  );
});

const getChartMeta = (series: any) => {
  if (!Array.isArray(series) || (series.length <= 0)) return '';
  const { startDate, endDate } = getCategorySeriesLimitDates(series);
  if (startDate && endDate) {
    return `${formatDate(startDate)} - ${formatDate(endDate)}`;
  }
  return '';
};

export const useAreaChartValues = (pastSeries: any, currentSeries: any) => {
  const { t } = useTranslation();
  return useMemo(() => {
    return [
      {
        dataKey: 'pastValue',
        label: t('network:analytics_past_period'),
        meta: getChartMeta(pastSeries)
      },
      {
        dataKey: 'currentValue',
        label: t('network:analytics_selected_period'),
        stroke: '#5856D6',
        meta: getChartMeta(currentSeries)
      }
    ];
  }, [t, pastSeries, currentSeries]);
};

export const useAreaChartData = (category: any) => {
  return useMemo(() => {
    const past = category.time_series?.past;
    return category.time_series?.current?.map((serie: any, index: number) => {
      const data: Record<string, any> = {
        date: serie.date,
        currentValue: serie.value,
      };
      if (past?.[index]) {
        data.pastValue = past?.[index].value;
        data.pastDate = past?.[index].date;
      }
      return data;
    });
  }, [category]);
};

const OverallEngagementScore = memo(({ data }: any) => {
  const { t } = useTranslation();
  const match = useRouteMatch();

  const {
    isAvailable: canSelectAnalyticsPerCategory, showUpgradeModal,
  } = useIsAvailableInPlanPackage(EPlanPackageConfig.PULSE_SURVEYS_ANALYTICS_PER_CATEGORY);

  const engagement = useMemo(() => {
    return data?.metrics?.find((metric: any) => {
      return metric.name === EPulseSurveyResultTypes.ENGAGEMENT;
    });
  }, [data]);

  const { startDate, endDate } = getCategoryLimitDates(engagement);
  const areaChartData = useAreaChartData(engagement);
  const areaChartValues = useAreaChartValues(
    engagement?.time_series?.past,
    engagement?.time_series?.current
  );

  const onOpenCategories = useMemo(() => {
    if (canSelectAnalyticsPerCategory) return undefined;
    return (e: any) => {
      e.preventDefault();
      showUpgradeModal();
    };
  }, [canSelectAnalyticsPerCategory, showUpgradeModal]);

  const toBadgeMetric = useCallback((metric: any) => {
    return {
      key: metric.name,
      name: metric.name,
      value: metric.value,
      change: metric.change,
      label: t(`survey:pulse_survey_category_${metric.name}`),
      onClick: onOpenCategories,
    };
  }, [t]);

  const categoriesMetrics = useMemo(() => {
    const categories = Object.values(EPulseSurveyCategories);
    return (data?.metrics || []).filter((metric: any) => {
      return categories.indexOf(metric.name) > -1;
    });
  }, [data]);

  const thrivingMetrics = useMemo(() => {
    return categoriesMetrics.filter((m: any) => m.value >= 3).map(toBadgeMetric);
  }, [categoriesMetrics, toBadgeMetric]);

  const lackingMetrics = useMemo(() => {
    return categoriesMetrics.filter((m: any) => m.value < 3).map(toBadgeMetric);
  }, [categoriesMetrics, toBadgeMetric]);

  const fluctuatedMetrics = useMemo(() => {
    return categoriesMetrics.filter((m: any) => m.change > 0.1).map(toBadgeMetric);
  }, [categoriesMetrics, toBadgeMetric]);

  return (
    <Column>
      <h2>
        { t('survey:pulse_analytics_overall_engagement_score') }
      </h2>
      <div className="charts">
        <div className="circleChart">
          <SimpleScoreCircleChart
            score={engagement.value}
            scoreChange={engagement.change}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
        <div className="areaChart">
          <AreaChart
            legend
            data={areaChartData}
            values={areaChartValues}
            XAxisDataKey="date"
            tint="#5856D6"
            CustomTooltip={
              <Tooltip tooltipValueLabel={t('survey:pulse_survey_score')} />
            }
          />
        </div>
      </div>
      {
        thrivingMetrics.length > 0 && (
          <Badges
            title={t('survey:pulse_analytics_thriving')}
            className="thriving"
            badges={thrivingMetrics}
          />
        )
      }
      {
        lackingMetrics.length > 0 && (
          <Badges
            title={t('survey:pulse_analytics_lacking')}
            badges={lackingMetrics}
          />
        )
      }
      {
        fluctuatedMetrics.length > 0 && (
          <Badges
            title={t('survey:pulse_analytics_fluctuating')}
            badges={fluctuatedMetrics}
          />
        )
      }
      <div className="bottomBar">
        <Link
          to={`${match.url}/categories`}
          onClick={onOpenCategories}
        >
          <Button
            iconRight="chevron_right"
            type="blue"
            size="large"
          >
            { t('survey:pulse_analytics_view_category_breakdown') }
          </Button>
        </Link>
      </div>
    </Column>
  );
});

export default OverallEngagementScore;
