import * as React from 'react';
import * as moment from 'moment';
import * as R from 'ramda';
import {
  ResponsiveContainer,
  AreaChart as AreaChartComponent,
  BarChart as BarChartComponent,
  Bar, XAxis, Area, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

import Icon from '../icon';
import {
  ActiveDotProps, AreaProps, BarLegendProps, BarProps, LegendProps, TooltipProps,
} from './types';

const ActiveDotComponent = ({ cx, cy, fill }: ActiveDotProps): JSX.Element | null => {
  if (cy === null) return null;
  return (
    <svg x={cx - 5} y={cy - 5} width="10" height="10" viewBox="0 0 10 10" fill="none">
      <circle cx="5" cy="5" r="5" fill={fill} />
      <circle cx="5" cy="5" r="3" fill="#FFF" />
      <circle cx="5" cy="5" r="2" fill={fill} />
    </svg>
  );
};


const DefaultCustomTooltip = ({
  active, payload, label, labelFormatter, valueFormatter,
}: TooltipProps): JSX.Element | null => {
  if (active) {
    return (
      <div className="Graph__Tooltip">
        <div className="Graph__Tooltip__Label">{label}</div>
        {payload && payload.map((row) => (
          <div className="Graph__Tooltip__Row" key={row.dataKey}>
            <div className="Graph__Tooltip__Row__Stroke" style={{ backgroundColor: row.stroke || row.fill }} />
            <div className="Graph__Tooltip__Row__Name">{labelFormatter ? labelFormatter(row.dataKey) : row.dataKey}</div>
            {/* @ts-expect-error */}
            {valueFormatter ? valueFormatter(row.payload[row.dataKey]) : row.payload[row.dataKey]}
          </div>
        ))}
      </div>
    );
  }

  return null;
};

const CustomLegend = ({ payload, formatter, title, tint }: LegendProps): JSX.Element => (
  <>
    {title && <div className="recharts-title" style={{ color: tint }}>{title}</div>}
    <ul className="recharts-default-legend">
      {payload.map(({ color, dataKey }, i) => (
        <li key={dataKey} className={`recharts-legend-item legend-item-${i}`}>
          <svg className="recharts-surface" width="8" height="8" viewBox="0 0 32 32" version="1.1">
            <path
              fill={color}
              className="recharts-symbols"
              transform="translate(16, 16)"
              d="M16,0A16,16,0,1,1,-16,0A16,16,0,1,1,16,0"
            />
          </svg>
          <span className="recharts-legend-item-text">
            {formatter ? formatter(dataKey) : dataKey}
          </span>
        </li>
      ))}
    </ul>
  </>
);

const format = {
  // @ts-expect-error
  date: (value) => moment(value).format('D MMM'),
  // @ts-expect-error
  name: (value) => (value.length >= 15 ? `${value.substr(0, 15)}...` : value),
};

// @ts-expect-error
const formatNumber = (value) => {
  if (value > 999999) return `${value / 1000000}m`;
  if (value > 999) return `${value / 1000}k`;

  return value;
};

const tickColor = '#404040';
const lineColor = '#E5E5EA';

export const AreaChart = ({
  data, title, values, XAxisDataKey = 'date', tint, CustomTooltip,
  legend = false
}: AreaProps) => (
  <div style={{ height: 320 }}>
    <ResponsiveContainer width="99%">
      <AreaChartComponent data={data} margin={{ bottom: 20 }}>
        <defs>
          {values.map(({ stroke }) => stroke && (
            <linearGradient key={stroke} id={`fill-${stroke}`} x1="0" y1="0" x2="0" y2="100%">
              <stop offset="0" stopColor={stroke} stopOpacity={0.25} />
              <stop offset="100%" stopColor="#FFF" stopOpacity={0} />
            </linearGradient>
          ))}
        </defs>
        <CartesianGrid stroke={lineColor} />
        <XAxis
          dataKey={XAxisDataKey}
          stroke={lineColor}
          tick={{ fill: tickColor }}
          interval="preserveStart"
          // @ts-expect-error
          tickFormatter={format[XAxisDataKey]}
          tickSize={0}
          tickMargin={16}
          minTickGap={24}
        />
        <YAxis
          stroke={lineColor}
          tick={{ fill: tickColor }}
          allowDecimals={false}
          tickSize={0}
          tickMargin={16}
          tickFormatter={formatNumber}
        />
        <Tooltip content={CustomTooltip || DefaultCustomTooltip} />
        {(title || legend) && (
          <Legend
            iconType="circle"
            verticalAlign="top"
            iconSize={8}
            height={36}
            // @ts-expect-error
            content={CustomLegend}
            title={title || undefined}
            tint={tint}
            formatter={((key) => {
              const value = R.find(R.propEq('dataKey', key), values);

              if (!value) return <b>{key}</b>;

              return [
                <b key="label">{value.label}</b>,
                // @ts-expect-error
                value.meta && <span key="meta" className="recharts-legend-item-meta">{value.meta}</span>,
              ];
            })}
          />
        )}
        {values.map(({ dataKey, stroke = '#C7C7CC', ...areaProps }) => (
          <Area
            key={dataKey}
            {...R.omit(['label'], areaProps)}
            dataKey={dataKey}
            stroke={stroke}
            fill={stroke ? `url(#fill-${stroke})` : 'none'}
            strokeWidth="2"
            activeDot={ActiveDotComponent}
          />
        ))}
      </AreaChartComponent>
    </ResponsiveContainer>
  </div>
);

const CustomBarLegend = ({
  values, onToggleBar, title, tint, formatter,
}: BarLegendProps): JSX.Element => (
  <>
    {title && <div className="recharts-title" style={{ color: tint }}>{title}</div>}
    <ul className="recharts-default-legend recharts-default-legend--left">
      {values.map(({ dataKey, fill, checked }, i) => (
        <li
          key={dataKey}
          className={`recharts-legend-item recharts-legend-item--checkbox legend-item-${i}`}
          style={{ color: fill }}
          onClick={() => onToggleBar(dataKey)}
        >
          <div className="recharts-legend-checkbox">
            {checked && <Icon type="check" />}
          </div>
          <span className="recharts-legend-item-text">
            {formatter ? formatter(dataKey) : dataKey}
          </span>
        </li>
      ))}
    </ul>
  </>
);

export const BarChart = ({
  data: preData, XAxisDataKey, values, orderKey, formatter, onToggleBar,
  legend = true,
  height = 400
}: BarProps) => {
  let data = preData;

  // @ts-expect-error
  const getTotal = (metric) => R.sum(R.values(R.omit([XAxisDataKey], metric)));

  if (orderKey) {
    // @ts-expect-error
    data = data.sort((a, b) => b[orderKey] - a[orderKey]);
  } else {
    data = data.sort((a, b) => getTotal(b) - getTotal(a));
  }

  // @ts-expect-error
  const keyToLabel = (key) => (R.find(R.propEq('dataKey', key), values) || { label: null }).label || key;

  return (
    <div style={{ overflowX: 'auto', paddingBottom: '16px' }}>
      <ResponsiveContainer
        // @ts-expect-error
        minWidth={data.length * (12 * values.filter((value) => value.checked).length + 16) + 60}
        height={height}
      >
        <BarChartComponent data={data} barCategoryGap={8} margin={{ bottom: 100 }}>
          <CartesianGrid stroke={lineColor} vertical={false} />
          <XAxis
            dataKey={XAxisDataKey}
            stroke={lineColor}
            interval={0} // Shows all labels
            // @ts-expect-error
            tickFormatter={format[XAxisDataKey]}
            tickMargin={6}
            tickSize={0}
            tick={{ fill: tickColor, textAnchor: 'end' }}
            angle={-60}
          />
          <YAxis
            stroke={lineColor}
            tickSize={0}
            tickMargin={16}
            tickFormatter={formatNumber}
            tick={{ fill: tickColor }}
          />
          <Tooltip
            content={DefaultCustomTooltip}
            labelFormatter={keyToLabel}
            // @ts-expect-error
            valueFormatter={formatter}
            cursor={{ fill: lineColor }}
          />
          {
            legend && (
              <Legend
                verticalAlign="top"
                height={36}
                values={values}
                // @ts-expect-error
                content={CustomBarLegend}
                onToggleBar={onToggleBar}
                formatter={keyToLabel}
              />
            )
          }
          {/* @ts-expect-error */}
          {values.map(({ dataKey, fill, checked }) => checked && (
            // @ts-expect-error
            <Bar key={dataKey} dataKey={dataKey} fill={fill} checked={checked} maxBarSize={24} />
          ))}
        </BarChartComponent>
      </ResponsiveContainer>
    </div>
  );
};
